import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Tooltip, Popover } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { eVariableType } from "../../../RegistrationRequest/constants";

import { Container, FreeText, Suggested, ScList, SCListItem } from "./styles";

function HSMVariable({ readonly, label, onChange, value, tip, type, maxLength, options }) {
  const anchorRef = useRef(null);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);

  const render = () => {
    if (readonly)
      return (
        <FreeText hasValue={!!value}>
          <span>{value || label}</span>
        </FreeText>
      );

    switch (type) {
      case eVariableType.free: {
        return (
          <FreeText hasValue={!!value}>
            <input
              type="text"
              defaultValue={value}
              maxLength={maxLength}
              onChange={({ target }) => onChange(target.value.substr(0, maxLength))}
            />
            <span>{value || label}</span>
          </FreeText>
        );
      }
      case eVariableType.fixed: {
        return (
          <FreeText hasValue={!!value}>
            <span>{value || label}</span>
          </FreeText>
        );
      }
      case eVariableType.suggested: {
        return (
          <Suggested ref={anchorRef} hasOpenned={open} hasValue={!!value}>
            <FreeText hasValue={!!value}>
              <input
                type="text"
                defaultValue={value}
                maxLength={maxLength}
                onChange={({ target }) => onChange(target.value.substr(0, maxLength))}
                ref={inputRef}
              />
              <span>{value || label}</span>
            </FreeText>
            {options?.length ? (
              <ChevronRightIcon onClick={() => setOpen(true)} />
            ) : (
              <ChevronRightIcon color="disabled" />
            )}
          </Suggested>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Container>
      <div>
        {tip ? (
          <Tooltip placement="top-start" title={tip} enterDelay={600} leaveDelay={100}>
            {render()}
          </Tooltip>
        ) : (
          render()
        )}
      </div>

      {!!options?.length && (
        <Popover
          open={open}
          anchorEl={anchorRef?.current}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          onClose={() => setOpen(false)}
        >
          <ScList component="ul">
            <b>
              <FormattedMessage defaultMessage="Sugestões" />
            </b>
            {options?.map(option => (
              <SCListItem
                component="li"
                key={option.id}
                onClick={() => {
                  if (inputRef?.current) inputRef.current.value = option.value;
                  onChange(option.value);
                  setOpen(false);
                }}
              >
                <span>{option.value}</span>
                <p>{option.label}</p>
              </SCListItem>
            ))}
          </ScList>
        </Popover>
      )}
    </Container>
  );
}

HSMVariable.propTypes = {
  readonly: PropTypes.bool,
  label: PropTypes.string,
  tip: PropTypes.string,
  type: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  options: PropTypes.array,
};

export default memo(HSMVariable);
