import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Container, MessageList } from "./styles";
import InputData from "./InputData";
import MessageBox from "./MessageBox";
import ActionHSM from "./ActionHSM";

function Chat({ chat, messages, onSendMessage, onSendNewFile, handleNewHsmMessage }) {
  const lastMessageRef = useRef(null);

  useEffect(
    () => {
      if (lastMessageRef) lastMessageRef.current.scrollIntoView();
    },
    [messages]
  );

  return (
    <Container>
      <MessageList>
        {messages.map(message => <MessageBox key={message.id} message={message} />)}
        <span ref={lastMessageRef} />
      </MessageList>

      {chat.envioMsgBloqueado ? (
        <ActionHSM handleSendHsm={handleNewHsmMessage} />
      ) : (
        <InputData onSendNewMessage={onSendMessage} onSendNewFile={onSendNewFile} />
      )}
    </Container>
  );
}

Chat.propTypes = {
  chat: PropTypes.object,
  messages: PropTypes.array,
  onSendMessage: PropTypes.func,
  onSendNewFile: PropTypes.func,
  handleNewHsmMessage: PropTypes.func,
};

export default Chat;
