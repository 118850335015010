/* eslint-disable react/no-danger */
import React, { useMemo, useCallback, memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Content = styled.span`
  display: inline;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
`;

const regexItalic = /(_(?=\S)(.*?)\S_)/g;
const regexBold = /(\*(?=\S)(.*?)\S\*)/g;
const regexScratched = /(~(?=\S)(.*?)\S~)/g;

function TextDecoration({ message, isModal, className }) {
  const parseToHtml = useCallback((str = "") => {
    let parsedText = str;

    const bolds = parsedText.match(regexBold) ?? [];
    bolds.forEach(m => {
      parsedText = parsedText.replace(m, `<b>${m.substr(1, m.length - 2)}</b>`);
    });

    const italics = parsedText.match(regexItalic) ?? [];
    italics.forEach(m => {
      parsedText = parsedText.replace(m, `<i>${m.substr(1, m.length - 2)}</i>`);
    });

    const scratcheds = parsedText.match(regexScratched) ?? [];
    scratcheds.forEach(m => {
      parsedText = parsedText.replace(m, `<s>${m.substr(1, m.length - 2)}</s>`);
    });

    return parsedText;
  }, []);

  const html = useMemo(() => parseToHtml(message), [message]);

  return <Content className={className} isModal={isModal} dangerouslySetInnerHTML={{ __html: html }} />;
}

TextDecoration.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  isModal: PropTypes.bool,
};

export default memo(TextDecoration);
