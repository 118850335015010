import styled, { css } from "styled-components";
import { IconButton } from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    span {
      font-size: 13px;
      padding: 2px 12px;
      color: #555;
    }
    > button {
      margin-right: 5px;
      padding: 7px;
      color: #e7818e;
      font-weight: 500;
      text-transform: uppercase;
      background: transparent;
      font-size: 13px;
      border: none;
      outline: none;
    }
  }

  ${({ isRecording }) =>
    isRecording &&
    css`
      position: absolute;
      z-index: 5;
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 2px;
      background: #f2f2f2;
      border-radius: 13px;
      padding: 5px 8px;
      border: 1px solid #d7d7d7;

      > div > svg {
        color: #e7818e !important;
        animation: icon-blink 1.5s linear infinite;
        transform: scale(1.2);
      }
    `};

  @keyframes icon-blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SCIconButton = styled(IconButton)`
  width: 33px;
  height: 33px;
  svg {
    color: #8e8e8e;
    font-size: 21px;
    margin: 0 0 2px 2px;
  }
`;
