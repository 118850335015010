import { defineMessages } from "react-intl";
import { appIntl } from "../../../_common/containers/ConnectedIntlProvider";
import store from "../../../store/store";
import { api } from "../services";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import defaultValues from "./Register/Forms/defaultValues";
import { eRequestStep, eDistributionType, eTemplateStatus } from "./constants";
import { openModalSendHSM } from "../../../store/reducers/whatsapp/actions";
import { CachedRequests } from "../../../_common/utils/cachedRequests";
import { AjaxBlackout } from "../../../_common";

const messages = defineMessages({
  errorRequest: {
    defaultMessage: "Erro ao processar solicitação.",
  },
  automaticMessageSave: {
    defaultMessage: "Mensagem automática salva",
  },
  erroProcessarSolicitacaoMotivo: {
    defaultMessage: "Erro ao processar solicitação. Motivo: {motivo}",
  },
});

export async function uploadFile(file) {
  const {
    usuario: { empresaClienteId },
  } = store.getState();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("empresaClienteId", empresaClienteId);

  const { data } = await api.post(`/api/pipeline/whatsapp/uploadmedia`, formData);
  if (data?.length) return { name: file.name, url: data };
  throw new Error();
}

export const saveConfig = async values => {
  const {
    usuario: { empresaClienteId },
  } = store.getState();

  try {
    AjaxBlackout.Show();
    Object.assign(values, { empresaClienteId });
    await api.post("/api/pipeline/whatsapp/solicitarwppbusiness", values);
    return values;
  } catch ({ message }) {
    createSnackBar(messages.errorRequest);
    return Promise.reject(message);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const loadConfig = async () => {
  try {
    const { data } = await api.get("/api/pipeline/whatsapp/buscarsolicitacaowpp");
    const dataToReturn = { ...defaultValues };
    if (data) {
      const { etapaSolicitacao, tipoDistribuicao } = data;
      Object.assign(dataToReturn, {
        ...data,
        solicitadoExpansao: etapaSolicitacao === eRequestStep.expansionOrder,
        tipoDistribuicao: tipoDistribuicao || eDistributionType.pvOnline,
      });
    }

    Object.keys(dataToReturn).forEach(key => {
      if (dataToReturn[key] === null) dataToReturn[key] = "";
    });

    return dataToReturn;
  } catch ({ message }) {
    createSnackBar(messages.errorRequest);
    Promise.reject(message);
    return { ...defaultValues };
  }
};

export const loadGroups = async () => {
  try {
    const { data } = await api.get("/api/pipeline/grupo/listarWpp");
    return data || [];
  } catch ({ message }) {
    return Promise.reject(message);
  }
};

export const saveAutomaticMessages = async ({ horarioComercial, horarioNaoComercial }) => {
  try {
    const data = { horarioComercial, horarioNaoComercial };
    await api.post("/api/pipeline/whatsapp/SalvarMsgAutomaticas", data);
    return createSnackBar(messages.automaticMessageSave);
  } catch ({ message }) {
    createSnackBar(messages.errorRequest);
    return Promise.reject(message);
  }
};

export const loadTemplateList = async status => {
  try {
    const { data } = await api.post("/api/pipeline/whatsapp/listartemplates", { status });

    return data || [];
  } catch ({ message }) {
    createSnackBar(messages.errorRequest);
    return Promise.reject(message);
  }
};

export const loadTemplate = async (templateId, leadId = 0) => {
  try {
    const { data } = await api.get(`/api/pipeline/whatsapp/buscartemplateporid?id=${templateId}&leadId=${leadId}`);

    return data || null;
  } catch ({ message }) {
    createSnackBar(messages.errorRequest);
    return Promise.reject(message);
  }
};

export const changeStatusTemplate = async (template, status) => {
  const {
    usuario: { empresaClienteId },
  } = store.getState();

  try {
    const dto = {
      id: template.id,
      nome: template.nome,
      empresaClienteId,
      status,
    };
    const { data } = await api.post("/api/pipeline/whatsapp/alterarstatustemplate", dto);

    return data || null;
  } catch ({ message }) {
    createSnackBar(messages.errorRequest);
    return Promise.reject(message);
  }
};

export const newHsmMessage = async ({ destinatario, leadId }) => {
  function Recipient(id, idLead, nomeLead, nome, ddi, telefone) {
    return { id, leadId: idLead, nomeLead, nome, ddi: String(ddi ?? ""), telefone: String(telefone) };
  }
  let destinatarios = [];

  try {
    if (leadId) {
      const recipients = [];

      const [{ data: company }, { data: contacts }] = await Promise.all([
        api.get(`/api/pipeline/lead/empresa?id=${leadId}`),
        api.get(`/api/pipeline/lead/contatos?id=${leadId}`),
      ]);

      if (company) {
        if (company.telefones?.length) {
          company.telefones.forEach(({ id, ddi, numero }) => {
            recipients.push(new Recipient(id, leadId, company.nome, company.nome, ddi, numero));
          });
        }

        if (contacts?.length) {
          contacts.forEach(contact => {
            contact.telefones.forEach(({ id, ddi, numero }) => {
              recipients.push(new Recipient(id, leadId, company.nome, contact.nome, ddi, numero));
            });
          });
        }
      }
      destinatarios = recipients;
    }

    const templates = await loadTemplateList([eTemplateStatus.approved]);

    store.dispatch(openModalSendHSM(templates, { destinatarios, destinatario }));
  } catch (err) {
    createSnackBar(messages.errorRequest);
  }
};

export const loadAutomaticMessages = async () => {
  try {
    const { data } = await api.get("/api/pipeline/whatsapp/buscarmsgautomaticas");
    return data || {};
  } catch (err) {
    return {};
  }
};

export const loadShortcutMessage = async ({ filterByActive = false }) => {
  const intl = appIntl();
  try {
    const URL = "/api/pipeline/atalhomensagens/listar";

    const data = filterByActive
      ? await CachedRequests.get(
          URL,
          async () => (await api.get(URL, { params: { somenteAtivos: filterByActive } })).data
        )
      : await (await api.get(URL, { params: { somenteAtivos: filterByActive } })).data;

    const shortcuts = data ?? [];
    return shortcuts.map(x => ({ ...x, palavrasChave: x.palavrasChave.split(",") }));
  } catch (err) {
    createSnackBar(intl.formatMessage(messages.erroProcessarSolicitacaoMotivo, { motivo: err.message }));
    return [];
  }
};

export const removeShortcutMessage = async id => {
  const intl = appIntl();
  try {
    AjaxBlackout.Show();
    await api.delete(`/api/pipeline/atalhomensagens/remover?id=${id}`);

    removeCacheShortcutMessages();
    return true;
  } catch (err) {
    createSnackBar(intl.formatMessage(messages.erroProcessarSolicitacaoMotivo, { motivo: err.message }));
    return Promise.reject(err);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const saveShortcutMessage = async shortcut => {
  const intl = appIntl();
  const {
    usuario: { empresaClienteId },
  } = store.getState();

  shortcut.empresaClienteId = empresaClienteId;
  shortcut.palavrasChave = shortcut.palavrasChave.join(",");

  try {
    AjaxBlackout.Show();
    await api.post("/api/pipeline/atalhomensagens/salvar", shortcut);

    removeCacheShortcutMessages();
    return true;
  } catch (err) {
    createSnackBar(intl.formatMessage(messages.erroProcessarSolicitacaoMotivo, { motivo: err.message }));
    return Promise.reject(err);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const removeCacheShortcutMessages = () => CachedRequests.invalidateUrl("/api/pipeline/atalhomensagens/listar");

export const loadInformationPlan = async () => {
  try {
    const { data } = await api.get("/api/pipeline/whatsapp/buscarinformacoesplano");
    return data || null;
  } catch (err) {
    return null;
  }
};
