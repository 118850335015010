import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import AsyncSelect from "../../../components/AsyncSelect";

import { Container } from "./styles";

function TransferChat({ handleTransfer }) {
  const intl = useIntl();
  const [user, setUser] = useState(null);
  const [transferLead, setTransferLead] = useState(true);

  return (
    <Container>
      <div>
        <strong>
          <FormattedMessage defaultMessage="Transferir chat vai fazer com que:" />
        </strong>
        <ul>
          <li>
            <FormattedMessage defaultMessage="Desative as notificações para você" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Retire seu acesso para responder este chat nesta janela (apenas Lista de chats)" />
          </li>
        </ul>

        <AsyncSelect
          style={{ margin: 0 }}
          fullWidth
          value={user?.id}
          enableClearValue
          url="/api/pipeline/whatsapp/listarprevendedores"
          getLabel={item => item.nome}
          label={intl.formatMessage({ defaultMessage: "Pré-vendedor(es)" })}
          searchLabel={intl.formatMessage({
            defaultMessage: "Buscar pré vendedor",
          })}
          onChange={(e, selectedUser) => setUser(selectedUser)}
        />

        <FormControlLabel
          control={
            <Checkbox checked={transferLead} onChange={() => setTransferLead(x => !x)} value="accept" color="primary" />
          }
          label={intl.formatMessage({
            defaultMessage: "Transferir Lead ao pré-vendedor que receber o chat",
          })}
        />

        <Button
          disabled={!(user && user.id)}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleTransfer(user, transferLead)}
        >
          <FormattedMessage defaultMessage="Transferir" />
        </Button>
      </div>
    </Container>
  );
}

TransferChat.propTypes = {
  handleTransfer: PropTypes.func.isRequired,
};

export default TransferChat;
