import React, { useEffect, useMemo, useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";

import Slider from "@appigram/react-rangeslider";
import { Container, Time, Button } from "./styles";

function MediaPlayer({ source, type, isModal = false }) {
  const playerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playbackPosition, setPlaybackPosition] = useState(0);

  useEffect(
    () => {
      if (playerRef?.current && loading) {
        playerRef.current.load();
        playerRef.current.onended = () => setPlaying(false);
        playerRef.current.onloadeddata = () => setLoading(false);
        playerRef.current.ontimeupdate = () => setPlaybackPosition(playerRef.current.currentTime);
        playerRef.current.ondurationchange = () => {
          if (playerRef?.current?.duration === Infinity) {
            playerRef.current.currentTime = 10000000 * Math.random();
          }
          const timeDuration = playerRef?.current?.duration ?? 0;
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(timeDuration) && timeDuration !== Infinity && typeof timeDuration === "number") {
            playerRef.current.currentTime = 0;
            setDuration(timeDuration);
          }
        };
      }
    },
    [playerRef?.current]
  );

  const formattedTime = useMemo(
    () =>
      `${moment.utc(moment.duration(playbackPosition, "seconds").asMilliseconds()).format("mm:ss")}/${moment
        .utc(moment.duration(duration, "seconds").asMilliseconds())
        .format("mm:ss")}`,
    [playbackPosition, duration]
  );

  const totalProgress = useMemo(() => moment.duration(duration * 1000).as("seconds"), [playbackPosition, duration]);

  useEffect(
    () => {
      if (playing) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    },
    [playing]
  );

  return (
    <Container isModal={isModal}>
      {type === "audio" && <audio ref={playerRef} preload="auto" src={source} />}
      {type === "video" && <video ref={playerRef} preload="auto" src={source} />}
      <div>
        <Button onClick={() => setPlaying(p => !p)}>{playing ? <Pause /> : <PlayArrow />}</Button>
        <Time>{loading ? <CircularProgress size={15} color="inherit" /> : formattedTime}</Time>
        <Slider
          orientation="horizontal"
          value={playbackPosition}
          onChange={time => {
            playerRef.current.currentTime = time;
          }}
          min={0}
          max={totalProgress}
          step={0.1}
          tooltip={false}
        />
      </div>
    </Container>
  );
}

MediaPlayer.propTypes = {
  source: PropTypes.string,
  type: PropTypes.oneOf(["audio", "video"]),
  isModal: PropTypes.bool,
};

export default memo(MediaPlayer);
