/* eslint-disable camelcase */
import "react-app-polyfill/stable";
import axios from "axios";

import { parseSecondsToHours } from "../../../_common/utils/string";
import { INTERNAL_SERVER_ERROR } from "../../../_common/utils/constants/httpStatusCodes";
import TelephonyService from "./TelephonyService";
import Commands from "./CallCommands";
import Call from "./Call";
import * as states from "./CallState";

export default class PhoneDialer {
  constructor(Credenciais, StatusCallback) {
    this.resource = "calls";
    this.urlCallsService = "https://telefonia.exactsales.com.br/";
    this.key = "Call";
    this.telephonyService = new TelephonyService();
    this.providerSettings = null;
    this.managedCall = null;
    this.telephoneProviderAxios = null;
    this.callManagerProviderAxios = null;
    this.listener = null;
    this.dadosCredenciais = Credenciais;
    this.StatusCallback = StatusCallback;

    this.callManagerProviderAxios = axios.create({
      headers: {
        "Content-Type": "application/json",
        token_auth: Credenciais.token_auth,
      },
      responseType: "json",
    });

    this.telephoneProviderAxios = axios.create({
      headers: {
        "Content-Type": "application/json",
        token_auth: Credenciais.token_auth,
      },
      responseType: "json",
    });
    this.managedCall = this.getCurrentCall();
    if (this.managedCall != null) this.makeCall(this.managedCall);
  }

  async makeCall(call, callbackFromView) {
    try {
      if (this.managedCall == null) {
        this.managedCall = call;
        await this.isReadyToCall();
        await this.initCall(callbackFromView);
        await this.updateManagedCall(states.SENT, callbackFromView);
        await this.telephonyService.saveCallData(this.managedCall);
        await this.manageCallState(callbackFromView);
      } else {
        await this.manageCallState(callbackFromView);
      }
    } catch (error) {
      throw error;
    }
  }

  async registerCallStart(data) {
    const Dados = data;
    Dados.provedorUrl = this.dadosCredenciais.provedorUrl;
    Dados.appUrl = window.location.hostname;
    await this.callManagerProviderAxios.post(`${this.urlCallsService}/startcall`, Dados);
  }

  async initCall(callbackFromView) {
    try {
      await this.telephoneProviderAxios
        .post(
          `${this.dadosCredenciais.provedorUrl}/${this.resource}`,
          Commands.generateCallData(this.managedCall.caller, this.managedCall.called)
        )
        .then(resp => {
          this.managedCall.callId = resp.data.callId;
          this.updateManagedCall(states.RECEIVED, callbackFromView);
        })
        .catch(error => {
          if (error.response.status === INTERNAL_SERVER_ERROR) {
            this.updateManagedCall(states.FAILED, callbackFromView);
          }
        });
      await this.registerCallStart(this.managedCall);
    } catch (error) {
      this.updateManagedCall(states.FAILED, callbackFromView);
      // eslint-disable-next-line
      console.error(error);
    }
  }

  async manageCallState(callbackFromView) {
    if (this.managedCall != null) {
      this.telephoneProviderAxios
        .get(`${this.dadosCredenciais.provedorUrl}/${this.resource}/${this.managedCall.callId}`)
        .then(resp => {
          if ([states.FINISHED, states.FAILED, states.NOANSWER].includes(resp.data.state)) {
            this.stopCall();
            this.managedCall.state = resp.data.state;
            this.StatusCallback(this.managedCall);
            return;
          }
          this.startListener(this.manageCallState, this, this.managedCall);
          if (this.StatusCallback && typeof this.StatusCallback === typeof Function) {
            this.managedCall.state = resp.data.state;
            this.managedCall.talkingDurationSeconds = resp.data.talkingDurationSeconds;
            localStorage.setItem(this.key, JSON.stringify(this.managedCall));
            this.StatusCallback(this.managedCall);
          }
        })
        .catch(error => {
          this.updateManagedCall(states.FAILED, callbackFromView);
          this.StatusCallback(this.managedCall);
          let msg = error.response.data.message;
          this.stopCall();
          if (error.response.status >= INTERNAL_SERVER_ERROR) {
            msg = "Houve um erro no provedor de telefonia";
          }
          throw new Error(msg);
        });
    }
  }

  endCall() {
    return new Promise((resolve, reject) => {
      if (this.managedCall.state === states.ESTABLISHED) {
        this.telephoneProviderAxios
          .post(
            `${this.dadosCredenciais.provedorUrl}/${this.resource}/endcall`,
            Commands.endCall(this.managedCall.callId)
          )
          .then(() => {
            this.isFinished = true;
            this.stopCall();
            resolve(this.managedCall);
          })
          .catch(() => {
            reject(new Error("Houve um erro ao encerrar a chamada"));
          });
      } else {
        this.isFinished = true;
        this.stopCall();
        resolve(this.managedCall);
      }
    });
  }

  callSummary() {
    const callSummary = `${this.dadosCredenciais.provedorUrl}/${this.resource}/${this.managedCall.callId}/summary/`;
    return new Promise((resolve, reject) => {
      this.telephoneProviderAxios
        .get(callSummary)
        .then(() => {
          this.isFinished = true;
          this.stopCall();
          resolve(this.managedCall);
        })
        .catch(() => {
          reject(new Error("Houve um erro ao encerrar a chamada"));
        });
    });
  }

  getCurrentCall() {
    const call = this.getFromLocalStorage(this.key);
    if (call != null) {
      if (call.state === states.FAILED) {
        localStorage.removeItem(this.key);
      } else return call;
    }
    return null;
  }

  stopCall() {
    this.stopListener();
    const call = this.getFromLocalStorage(this.key);
    if (call != null) {
      localStorage.removeItem(this.key);
    }
  }

  async isReadyToCall() {
    // Validar condições de ligação
    return true;
  }

  stopListener() {
    clearTimeout(this.listener);
    this.listener = null;
  }

  startListener(fun, context, paramas) {
    this.stopListener();
    this.listener = setTimeout(fun.bind(context), 2000, paramas);
  }

  updateManagedCall(state, callbackFromView) {
    this.managedCall.state = state;
    localStorage.setItem(this.key, JSON.stringify(this.managedCall));

    if (callbackFromView && typeof callbackFromView === typeof Function) {
      callbackFromView(this.managedCall);
    }
  }

  getFromLocalStorage() {
    if (typeof localStorage !== "undefined") return JSON.parse(localStorage.getItem(this.key));
    throw new Error("O navegador não tem suporte para local storage");
  }
}

(() => {
  window.Call = Call;
  window.PhoneDialer = PhoneDialer;
  window.parseSecondsToHours = parseSecondsToHours;
})();
