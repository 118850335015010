import React, { memo } from "react";
import PropTypes from "prop-types";

function TextVariables({ template, variables, onChange, ...rest }) {
  const regex = new RegExp(/\{\{(.*?)\}\}/);
  const textVariables = template.match(/({{(?=\S)(.*?)\S}})/g);

  if (textVariables) {
    return (
      <>
        {template.split(regex).map(part => {
          const variable = textVariables.find(v => v.replace(/([{}])/g, "") === part);
          if (variables[part]?.component) {
            const Component = variables[part].component;
            const { props } = variables[part] ?? null;
            return (
              <Component
                key={part}
                {...props}
                {...rest}
                onChange={params => onChange({ variable, key: part, props, params })}
              />
            );
          } else if (variable) return variable;
          return part;
        })}
      </>
    );
  }
  return template;
}

TextVariables.defaultProps = {
  variables: {},
  onChange: () => {},
};

TextVariables.propTypes = {
  template: PropTypes.string.isRequired,
  variables: PropTypes.object,
  onChange: PropTypes.func,
};

export default memo(TextVariables);
