import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Container, LinkButton } from "./styles";

function ActionHSM({ handleSendHsm, isModal }) {
  return (
    <Container isModal={isModal}>
      <p>
        <FormattedMessage
          defaultMessage="Não é possível enviar mensagens após 24h da última mensagem recebida, use nossos <link>Templates de mensagem.</link>"
          values={{
            link: (...chunks) => (
              <LinkButton isModal={isModal} onClick={handleSendHsm}>
                {chunks}
              </LinkButton>
            ),
          }}
        />
      </p>
    </Container>
  );
}

ActionHSM.propTypes = {
  handleSendHsm: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
};

export default ActionHSM;
