import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Picker } from "emoji-mart";

import "emoji-mart/css/emoji-mart.css";
import useOutsideClick from "../../../hooks/useClickOutside";

import { Container } from "./styles";

function EmojiPicker({ onSelect, show, onClose, ...props }) {
  const pickerRef = useRef(null);
  useOutsideClick(pickerRef, onClose);

  return (
    <Container {...props} ref={pickerRef}>
      {show && <Picker native onSelect={onSelect} />}
    </Container>
  );
}

EmojiPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default EmojiPicker;
