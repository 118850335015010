import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

import { ActionButton } from "../../../components";
import FormVincularContato from "../ModalVincularContato/FormVincularContato";
import { Tipografia } from "../../../_common";
import { VincularContatoWppAoLead, VincularContatoWppAoContatoDoLead } from "../services";

function ModalVincularContato({ classes, modalVincularContatoOpen, handleVincularContato, infosChat, onSuccess }) {
  const intl = useIntl();
  const [open, setOpen] = useState(modalVincularContatoOpen);
  const [isConfirmacao, setIsConfirmacao] = useState(false);
  const [user, setUser] = useState(null);
  const [isVinculandoAoLead, setIsVinculandoAoLead] = useState(true);
  const [transferirChat, setTransferirChat] = useState(true);

  const handleTipoDeVinculacao = value => {
    setIsVinculandoAoLead(value);
  };

  const handleTransferirChat = () => {
    setTransferirChat(!transferirChat);
  };

  const handleToggleOpen = () => {
    setOpen(!open);
    setIsConfirmacao(false);
    handleVincularContato();
  };

  const handleConfirm = async () => {
    if (isConfirmacao) {
      if (isVinculandoAoLead) {
        await VincularContatoWppAoLead(user, infosChat, transferirChat);
      } else {
        await VincularContatoWppAoContatoDoLead(user, infosChat, transferirChat);
      }

      handleToggleOpen();
      setIsConfirmacao(false);
      if (onSuccess) onSuccess();
      return;
    }
    setIsConfirmacao(true);
  };

  const handleUser = userValues => {
    setUser(userValues);
  };

  useEffect(
    () => {
      setOpen(modalVincularContatoOpen);
    },
    [modalVincularContatoOpen]
  );

  return (
    <>
      <Dialog open={open} onClose={handleToggleOpen} fullWidth maxWidth="sm">
        <DialogTitle>{intl.formatMessage({ defaultMessage: "Vincular contato" })}</DialogTitle>
        <DialogContent>
          {!isConfirmacao && (
            <FormVincularContato
              handleUser={handleUser}
              handleTipoDeVinculacao={handleTipoDeVinculacao}
              handleTransferirChat={handleTransferirChat}
            />
          )}
          {isConfirmacao && (
            <Tipografia>
              {isVinculandoAoLead &&
                intl.formatMessage(
                  { defaultMessage: "O número será adicionado ao lead {nomeLead}. Deseja confirmar?" },
                  { nomeLead: user.descricao }
                )}

              {!isVinculandoAoLead &&
                intl.formatMessage(
                  {
                    defaultMessage:
                      "O número será adicionado ao contato {nomeContato} relacionado ao Lead {nomeLead}. Deseja confirmar?",
                  },
                  { nomeLead: user.nomeLead, nomeContato: user.nomeContato }
                )}
            </Tipografia>
          )}
        </DialogContent>
        <DialogActions className={classes.actionSection}>
          <ActionButton
            id="btn-abrir-modal-vincular-contato"
            type="text"
            label={intl.formatMessage({
              defaultMessage: "Cancelar",
            })}
            onClick={handleToggleOpen}
          />
          <Button
            id="btn-vincular-contato"
            type="submit"
            color="primary"
            form="form-vincular-contato"
            onClick={handleConfirm}
          >
            {intl.formatMessage({ defaultMessage: "Confirmar" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ModalVincularContato.propTypes = {
  modalVincularContatoOpen: PropTypes.bool,
  handleVincularContato: PropTypes.func,
  infosChat: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default withStyles({
  labelButton: {
    display: "flex",
  },
  actionSection: {
    justifyContent: "center",
  },
})(ModalVincularContato);
