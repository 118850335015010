import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";

import { formatPhoneMask } from "../../../_common/utils/phone";
import { Container } from "./styles";

function Disconnected({ handleConnect, ddi, phone }) {
  return (
    <Container>
      <div>
        <FormattedMessage
          defaultMessage="<strong> Conectar no WhatsApp </strong><span>Usando {phone}</span>"
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>,
            span: (...chunks) => <span>{chunks}</span>,
            phone: formatPhoneMask({ ddi, phone, showDDI: true }),
          }}
        />

        <Button fullWidth variant="contained" color="primary" onClick={handleConnect}>
          <FormattedMessage defaultMessage="Conectar" />
        </Button>
      </div>
    </Container>
  );
}

Disconnected.propTypes = {
  handleConnect: PropTypes.func.isRequired,
  phone: PropTypes.string,
  ddi: PropTypes.string,
};

export default Disconnected;
