import styled, { css } from "styled-components";
import { Avatar } from "@material-ui/core";

export const Container = styled.li`
  width: 305px;
  height: 63px;
  display: flex;
  align-items: center;
  opacity: ${({ readOnly }) => (readOnly ? 0.55 : 1)};
  background: #fff;
  user-select: none;
  position: relative;
`;

export const AvatarContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 47px;
    height: 47px;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 9px;
  border-bottom: 2px solid #f6f6f6;
  cursor: pointer;
  p,
  b {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const StyledAvatar = styled(Avatar)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #ffff;
    background: #a1c3ed;
    border-radius: 50%;
    letter-spacing: 2px;
    cursor: default;
  }
`;

export const StyledAvatarButton = styled(Avatar)`
  && {
    background: #aaaaaa;
    cursor: pointer;
    svg {
      color: #f4f4f4;
    }
  }
`;

export const Name = styled.b`
  color: #333;
  font-size: 15px;
  padding-right: 5px;
`;

export const Message = styled.p`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #b3b3b3;
  margin: 0;
  margin-top: 5px;
  > svg {
    width: 16px;
    height: 16px;
    padding-right: 5px;
  }
`;

export const Time = styled.span`
  color: #6591c3;
  font-size: 10px;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  top: -5px;
  ${({ danger }) =>
    danger &&
    css`
      color: #ec4a2c;
      font-style: italic;
      text-transform: unset;
    `};
`;

export const Badge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #304355;
  color: #fff;
  height: 19px;
  width: 19px;
  font-size: 11px;
  position: relative;
  bottom: -3px;
`;
