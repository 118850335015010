import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import "../../../../components/AudioPlayer/AudioPlayer.css";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    padding: 5px 13px;
    width: 100%;

    .rangeslider-horizontal {
      width: 100%;
      min-width: 80px;
    }
  }
  > audio {
    display: none;
  }

  > video {
    max-width: 100%;
    max-height: ${({ isModal }) => (isModal ? "350px" : "145px")};
  }
`;

export const Time = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.darkPrimaryText};
  margin: 0 13px;
`;

export const Button = styled(IconButton)`
  && {
    height: 12px;
    width: 12px;
    svg {
      font-size: 24px;
      color: ${({ theme }) => theme.palette.icons.darkActiveIcon}!important;
    }
  }
`;
