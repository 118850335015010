import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { useDispatch } from "react-redux";

import { Loading } from "../_common";
import { API_PUBLICA } from "../_common/constantes";
import ApiContext from "../context/ApiContext";
import { configureMoment, setLocale } from "../store/reducers/locale/localeActions";
import ConnectedIntlProvider from "../_common/containers/ConnectedIntlProvider";
import SnackProvider from "../_common/components/SnackProvider";
import SolicitaAlteracaoPlano from "../_common/components/Funcionalidade/SolicitaAlteracaoPlano";
import ConfirmaAlteracaoPlano from "../_common/components/Funcionalidade/ConfirmaAlteracaoPlano";

const Feedback = React.lazy(() => import("../pages/Feedback"));
const Lead = React.lazy(() => import("./Lead"));
const Questionario = React.lazy(() => import("./questionario"));
const CriarSenha = React.lazy(() => import("./CriarSenha"));
const TrocarSenha = React.lazy(() => import("./TrocarSenha"));
const Calendario = React.lazy(() => import("./Calendario"));
const AgendaInteligenteConfirmacao = React.lazy(() => import("./AgendaInteligente/Confirmacao"));
const ConferenciaAcessar = React.lazy(() => import("./Conferencia/ConferenciaAcessar"));
const ConferenciaEncerrar = React.lazy(() => import("./Conferencia/ConferenciaEncerrar"));
const OrganizacaoDetalhePage = React.lazy(() => import("./organizacao/DetalhePage"));
const ContaPublica = React.lazy(() => import("../pages/conta/ContaPublica"));
const GooglePublicoSucesso = React.lazy(() => import("./GooglePublico/GooglePublicoSucesso"));
const GooglePublicoErro = React.lazy(() => import("./GooglePublico/GooglePublicoErro"));
const Office365Sucesso = React.lazy(() => import("./Office365Publico/Office365PublicoSucesso"));
const Office365Erro = React.lazy(() => import("./Office365Publico/Office365PublicoErro"));
const Descadastro = React.lazy(() => import("../pages/cadencia-emails/Descadastros/Descadastro"));
const CallbackRD = React.lazy(() => import("../pages/configuracoes/Integrations/Marketing/RDStation/Callback"));
const CallbackHubSpot = React.lazy(() => import("../pages/configuracoes/Integrations/Marketing/HubSpot/Callback"));
const CallbackOAuth = React.lazy(() => import("../pages/configuracoes/Integrations/Crm/Callback"));
const ApiV3Erro = React.lazy(() => import("./APIV3/APIV3PublicoErro"));
const RecuperarSenha = React.lazy(() => import("./RecuperarSenha/RecuperarSenha"));

export default function PublicRouter() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const idiomas = ["en-US", "es-ES", "pt-BR"];
    const lang = idiomas.includes(navigator.language) ? navigator.language : "pt-BR";
    dispatch(setLocale(lang));
    configureMoment(lang);
    document.body.style.margin = "0";

    return () => {
      document.body.style.margin = "";
    };
  }, []);

  return (
    <React.Suspense fallback={<Loading isLoading />}>
      <ConnectedIntlProvider>
        <ApiContext.Provider value={API_PUBLICA}>
          <SnackProvider>
            <Switch>
              <Route path="/reuniao/:reuniaoId" render={props => <Feedback {...props} isPublic />} />
              <Route path="/conferencia/:conferenciaId" component={ConferenciaAcessar} />
              <Route path="/public/organizacao/:orgId" component={OrganizacaoDetalhePage} />
              <Route path="/public/google-publico/sucesso" component={GooglePublicoSucesso} />
              <Route path="/public/google-publico/erro" component={GooglePublicoErro} />
              <Route path="/public/office365/sucesso" component={Office365Sucesso} />
              <Route path="/public/office365/erro" component={Office365Erro} />
              <Route path="/public/cadencia-emails/descadastro" component={Descadastro} />
              <Route exact path="/public/video-conferencia-encerrar" component={ConferenciaEncerrar} />
              <Route exact path="/public/criar-senha" render={props => <CriarSenha {...props} isPublic />} />
              <Route exact path="/public/trocar-senha" render={props => <TrocarSenha {...props} isPublic />} />
              <Route exact path="/public/recuperar-senha" component={RecuperarSenha} />
              <Route exact path="/public/calendario" component={Calendario} />
              <Route exact path="/public/agenda-inteligente-confirmar" component={AgendaInteligenteConfirmacao} />
              <Route exact path="/public/questionario" component={Questionario} />
              <Route exact path="/public/painel-financeiro" component={ContaPublica} />
              <Route exact path="/public/:leadId" component={Lead} />
              <Route path="/public/funcionalidade-plano/solicita-alteracao" component={SolicitaAlteracaoPlano} />
              <Route path="/public/funcionalidade-plano/confirma-alteracao-plano" component={ConfirmaAlteracaoPlano} />
              <Route path="/public/oauthrd/callback" component={CallbackRD} />
              <Route path="/public/oauthHubSpot/callback" component={CallbackHubSpot} />
              <Route path="/public/oauth/callback" component={CallbackOAuth} />
              <Route path="/public/apiv3/erro" component={ApiV3Erro} />
              <Route component={() => <Redirect to="/" />} />
            </Switch>
          </SnackProvider>
        </ApiContext.Provider>
      </ConnectedIntlProvider>
    </React.Suspense>
  );
}
