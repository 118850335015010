export const eTypesTelephone = {
  personalFixed: 1,
  personalCellPhone: 2,
};

export const eRequestStep = {
  facebookId: 1,
  companyData: 2,
  responsibleData: 3,
  dataSent: 4,
  expansionOrder: 5,
};

export const eRequestStatus = {
  awaitingUpdatesFacebook: 1,
  awaitingCompanyAuthorization: 2,
  awaitingFacebookApproval: 3,
  approved: 4,
  cancelled: 5,
  getIntlLabel(status, intl) {
    return {
      [this.awaitingUpdatesFacebook]: intl.formatMessage({
        defaultMessage: "Aguardando atualizações do Facebook Business.",
      }),
      [this.awaitingCompanyAuthorization]: intl.formatMessage({
        defaultMessage: "Aguardando sua autorização do Facebook Business.",
      }),
      [this.awaitingFacebookApproval]: intl.formatMessage({
        defaultMessage: "Aguardando aprovação dos dados pelo Facebook Business.",
      }),
      [this.approved]: intl.formatMessage({ defaultMessage: "Realizado e Aprovado" }),
    }[status];
  },
};

export const eStep = {
  setp1: 1,
  step2: 2,
  step3: 3,
  configs: 4,
  expansionOrder: 5,
};

export const eDistributionType = {
  pvOnline: 1,
  pvGroups: 2,
};

export const eMensagemAutomatica = {
  horarioComercial: "horarioComercial",
  horarioNaoComercial: "horarioNaoComercial",
};

export const eTabs = {
  business: "business",
  templates: "templates",
  shortcuts: "shortcuts",
};

export const eTemplateStatus = {
  available: 1,
  requested: 2,
  approved: 3,
  rejected: 4,
  disabled: 5,
  getIntlLabel(status, intl) {
    return {
      [this.approved]: intl.formatMessage({
        defaultMessage: "Aprovado",
      }),
      [this.available]: intl.formatMessage({
        defaultMessage: "Disponível",
      }),
      [this.rejected]: intl.formatMessage({
        defaultMessage: "Rejeitado",
      }),
      [this.requested]: intl.formatMessage({
        defaultMessage: "Solicitação em andamento",
      }),
      [this.disabled]: intl.formatMessage({ defaultMessage: "Desativado" }),
    }[status];
  },
};

export const eVariableType = {
  free: 1,
  suggested: 2,
  fixed: 3,
};

export function ShortcutMessage() {
  return {
    id: null,
    nome: "",
    ativo: true,
    ultimaAtualizacao: new Date(),
    mensagem: "",
    atalho: "",
    palavrasChave: [],
  };
}
