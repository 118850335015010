import React, { memo, useCallback } from "react";
import Proptypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { Tooltip } from "react-tippy";
import GetAppRounded from "@material-ui/icons/GetAppRounded";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import AnnouncementIcon from "@material-ui/icons/Announcement";

import MultiPlayer from "../MultiPlayer";
import {
  Container,
  Spinner,
  Media,
  DownloadButton,
  Info,
  ContactLine,
  PersonIcon,
  MessageLabel,
  Text,
  EndServiceButton,
} from "./styles";
import { MESSAGETYPES, ORIGEM_MSG, STATUSMESSAGE, TIPO_PERFIL } from "../../constants";

import download from "../../../../_common/utils/downloadContent";

function MessageBox({ message, isModal = false, handleEndService }) {
  const intl = useIntl();
  const getMediaComponent = useCallback(msg => {
    switch (msg.tipo) {
      case MESSAGETYPES.IMAGE:
        return (
          <React.Fragment>
            <img src={msg.mensagem} alt={msg.tipo} />
            <section>
              <div>
                <FormattedMessage defaultMessage="Arquivo de Imagem" />
              </div>
              <Tooltip title={intl.formatMessage({ defaultMessage: "Baixar arquivo" })} position="bottom-start">
                <DownloadButton onClick={() => download(msg.mensagem)}>
                  <GetAppRounded />
                </DownloadButton>
              </Tooltip>
            </section>
          </React.Fragment>
        );

      case MESSAGETYPES.AUDIO:
        return (
          <React.Fragment>
            <MultiPlayer type="audio" source={msg.mensagem} />
            <section>
              <div>
                <FormattedMessage defaultMessage="Arquivo de Áudio" />
              </div>
              <Tooltip title={intl.formatMessage({ defaultMessage: "Baixar arquivo" })} position="bottom-start">
                <DownloadButton onClick={() => download(msg.mensagem)}>
                  <GetAppRounded />
                </DownloadButton>
              </Tooltip>
            </section>
          </React.Fragment>
        );

      case MESSAGETYPES.VIDEO:
        return (
          <React.Fragment>
            <MultiPlayer type="video" source={msg.mensagem} isModal={isModal} />
            <section>
              <div>
                <FormattedMessage defaultMessage="Arquivo de Video" />
              </div>
              <Tooltip title={intl.formatMessage({ defaultMessage: "Baixar arquivo" })} position="bottom-start">
                <DownloadButton onClick={() => download(msg.mensagem)}>
                  <GetAppRounded />
                </DownloadButton>
              </Tooltip>
            </section>
          </React.Fragment>
        );

      case MESSAGETYPES.APLICATION:
      case MESSAGETYPES.DOCUMENT:
        return (
          <React.Fragment>
            <p>
              <FormattedMessage defaultMessage="Documento" />
            </p>
            <section>
              <div>
                <FormattedMessage defaultMessage="Documento" />
              </div>
              <Tooltip title={intl.formatMessage({ defaultMessage: "Baixar arquivo" })} position="bottom-start">
                <DownloadButton onClick={() => download(msg.mensagem)}>
                  <GetAppRounded />
                </DownloadButton>
              </Tooltip>
            </section>
          </React.Fragment>
        );

      case MESSAGETYPES.CONTACT:
        return (
          <React.Fragment>
            <ContactLine isModal={isModal}>
              <PersonIcon />
              <span>{msg.nomeContato || <FormattedMessage defaultMessage="Contato" />}</span>
            </ContactLine>
            <section>
              <div>
                <FormattedMessage defaultMessage="Contato em .VCF" />
              </div>
              <Tooltip title={intl.formatMessage({ defaultMessage: "Baixar arquivo" })} position="bottom-start">
                <DownloadButton onClick={() => download(msg.mensagem)}>
                  <GetAppRounded />
                </DownloadButton>
              </Tooltip>
            </section>
          </React.Fragment>
        );

      case MESSAGETYPES.MEDIA:
        return (
          <React.Fragment>
            <p />
            <section>
              <div>
                <FormattedMessage defaultMessage="Carregando mídia..." />
              </div>
              <Spinner size={22} thickness={5} />
            </section>
          </React.Fragment>
        );

      default:
        return null;
    }
  }, []);

  const renderStatusIcon = useCallback(statusMessage => {
    switch (statusMessage) {
      case STATUSMESSAGE.SENT:
        return <DoneIcon />;

      case STATUSMESSAGE.DELIVERED:
      case STATUSMESSAGE.READ:
        return <DoneAllIcon />;

      case STATUSMESSAGE.ERROR:
        return (
          <Tooltip title={intl.formatMessage({ defaultMessage: "Mensagem não entregue" })} position="bottom-start">
            <ReportProblemIcon />
          </Tooltip>
        );

      default:
        return null;
    }
  }, []);

  if (message.isMessageLabel) {
    let label = "";

    if (typeof message.label === "object") {
      if (message.param) {
        const { param } = message;
        label = intl.formatMessage(message.label, { param });
      } else {
        label = intl.formatMessage(message.label);
      }
    } else label = message.label;

    return (
      <MessageLabel className="sc-messagebox-separator" type={message.type} isModal={isModal}>
        <span>{label}</span>
      </MessageLabel>
    );
  }

  const isGerente = message.enviadaPor === TIPO_PERFIL.Gerente;

  return (
    <Container isModal={isModal} isSent={message.origem === ORIGEM_MSG.SPOTTER} isGerente={isGerente}>
      <div>
        {message.tipo === MESSAGETYPES.TEXT ? (
          <Text isModal={isModal} message={message.mensagem} />
        ) : (
          <Media isModal={isModal}>
            {getMediaComponent(message)}
            {message.subtitleMedia && <span>{message.subtitleMedia}</span>}
          </Media>
        )}
        <Info isModal={isModal} read={message.statusMessage === STATUSMESSAGE.READ} isGerente={isGerente}>
          {message.exibeEncerrarChat && (
            <Tooltip
              title={intl.formatMessage({
                defaultMessage: "Finalizar atendimento",
              })}
            >
              <EndServiceButton onClick={handleEndService}>
                <AnnouncementIcon />
              </EndServiceButton>
            </Tooltip>
          )}

          {isGerente && (
            <div>
              <span>{intl.formatMessage({ defaultMessage: "Enviada pelo Gerente" })}</span>
            </div>
          )}
          <span>{message.formattedDate}</span>
          {message.origem === ORIGEM_MSG.SPOTTER && renderStatusIcon(message.statusMessage)}
        </Info>
      </div>
    </Container>
  );
}

MessageBox.propTypes = {
  message: Proptypes.object,
  isModal: Proptypes.bool,
  handleEndService: Proptypes.func,
};

export default memo(MessageBox);
