import styled from "styled-components";
import Close from "@material-ui/icons/Close";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(Close)`
  color: #fff;
`;

export const ContentCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-size: 15px;

  svg,
  p {
    color: #888;
  }

  svg {
    font-size: 60px;
    margin-bottom: 15px;
  }
`;

export const ListContacts = styled.ul`
  padding: 0;
  margin: 0;
  max-height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
`;
