import axios from "axios";

export default class TelephonyService {
  constructor() {
    this.exactAxios = axios.create({
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
      responseType: "json",
    });
  }

  async saveCallData(call) {
    return this.exactAxios.post("/CredencialLigacao/InsereHistoricoLigacaoApiPublica", {
      LeadId: call.leadId,
      Caller: call.caller,
      Called: call.called,
      FlGravaLigacao: true,
      CallId: call.callId,
      State: call.State,
    });
  }
}
