import styled from "styled-components";
import { LinearProgress, IconButton } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ChatBlubeIcon from "@material-ui/icons/ChatBubble";
import WhatsApp from "../WhatsAppIcon";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 29px 1fr 29px;
  grid-template-rows: 38px;
  grid-template-areas: "icon content actions";
  grid-column-gap: 3px;
  align-items: center;
  justify-content: start;
  width: 305px;
  height: 38px;
  padding: 0 10px;
  background: #304355;
  position: relative;

  span,
  div,
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Content = styled.div`
  grid-area: content;
  overflow: hidden;
  color: #fff;
`;

export const WhatsAppIcon = styled(WhatsApp)`
  grid-area: icon;
  height: 17px;
  width: 17px;
  color: #fff;
`;

export const ArrowBackIcon = styled(ArrowBack)`
  && {
    grid-area: icon;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
  }
`;

export const Contact = styled.section`
  color: #fff;

  > span {
    font-size: 15px;
    display: block;
    max-width: 200px;
  }

  p {
    font-size: 11px;
    margin: 0;
    span {
      font: inherit;
    }
  }
`;

export const Actions = styled.section`
  grid-area: actions;
  > div {
    position: relative;
    top: -2px;
    left: -199px;
  }
`;

export const MenuButton = styled(IconButton)`
  && {
    color: #fff;
    padding: 0 !important;
    width: 34px !important;
    height: 48px !important;
  }
`;

export const SCLinearProgress = styled(LinearProgress)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  z-index: 3;
  background: transparent;
`;

export const ChatBlube = styled(ChatBlubeIcon)`
  && {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 9px;
    font-size: 19px;
  }
`;
