import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { SentimentSatisfied, Send, AttachFile } from "@material-ui/icons";
import { Popover, Tooltip } from "@material-ui/core";

import Recorder from "./Recorder";
import { loadShortcutMessage } from "../../RegistrationRequest/services";
import { Container, AttachFileButton, Spinner, SCEmojiPicker, SCList, ListItem } from "./styles";

const KEYBOARD = {
  ArrowDown: 40,
  ArrowUp: 38,
  Enter: 13,
  Backspace: 8,
};

function InputData({ onSendNewMessage, onSendNewFile }) {
  const intl = useIntl();

  const [show, setShow] = useState(false);
  const [shortcutList, setShortcutList] = useState([]);
  const [shortcutListFind, setShortcutListFind] = useState([]);
  const [selected, setSelected] = useState(0);
  const [progress, setProgress] = useState(0);

  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const timerRef = useRef({});

  const handleSendMessage = useCallback(
    () => {
      setShortcutListFind([]);

      if (progress !== 0) return;
      const message = String(inputRef.current.value).trim();
      inputRef.current.value = "";
      if (message.length) {
        onSendNewMessage(message);
      }
    },
    [progress, inputRef, onSendNewMessage]
  );

  const handleUploadFile = useCallback(
    () => {
      const file = fileRef.current.files[0];
      fileRef.current.files = null;
      onSendNewFile(file, setProgress);
    },
    [inputRef, onSendNewFile]
  );

  const addEmoji = useCallback(
    ({ native }) => {
      inputRef.current.value += native;
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    },
    [inputRef]
  );

  const replaceMessage = useCallback(
    ({ mensagem, shortcut }) => {
      inputRef.current.value = inputRef.current.value.replace(shortcut, mensagem);
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
      setShortcutListFind([]);
    },
    [inputRef]
  );

  const handlePress = useCallback(
    e => {
      const textValue = e.target.value ?? "";
      const cursorPos = e.target.selectionStart;

      clearTimeout(timerRef?.current.timer);

      let responseList = shortcutList;

      if (textValue.length && responseList.length) {
        const shortcut = textValue.match(/\/(\w*)/g)?.pop();
        if (shortcut && shortcut === textValue.substring(textValue.indexOf(shortcut), cursorPos)) {
          const parts = shortcut.replace("/", "").toLowerCase();

          responseList = responseList
            .filter(
              x =>
                x.atalho?.toLowerCase()?.includes(parts) ||
                x.palavrasChave?.some(y => y?.toLowerCase()?.includes(parts))
            )
            .map(x => ({ ...x, shortcut }));

          if (responseList.length) {
            timerRef.current.timer = setTimeout(() => {
              setSelected(0);
              setShortcutListFind(responseList);
            }, 500);
          } else {
            setShortcutListFind([]);
          }
        }
      }

      if (e.keyCode === KEYBOARD.Enter && !e.shiftKey) {
        e.preventDefault();
        if (textValue.length) {
          handleSendMessage();
        }
      }
    },
    [timerRef, shortcutList, handleSendMessage]
  );

  const handleNavigateList = useCallback(
    e => {
      e.preventDefault();
      if (shortcutListFind.length) {
        const listCount = shortcutListFind.length - 1;

        switch (e.keyCode) {
          case KEYBOARD.ArrowUp: {
            if (selected >= 1) setSelected(selected - 1);
            else setSelected(listCount);
            break;
          }
          case KEYBOARD.ArrowDown: {
            if (selected <= listCount - 1) setSelected(selected + 1);
            else setSelected(0);
            break;
          }
          case KEYBOARD.Enter: {
            replaceMessage(shortcutListFind[selected]);
            break;
          }
          case KEYBOARD.Backspace: {
            inputRef.current.value = inputRef.current.value.substring(0, inputRef.current.value.length - 1);
            inputRef.current.dispatchEvent(new Event("keyup", { bubbles: true }));
            break;
          }
          default: {
            if (
              (e.keyCode >= 65 && e.keyCode <= 90) ||
              (e.keyCode >= 97 && e.keyCode <= 122) ||
              (e.keyCode >= 48 && e.keyCode <= 57)
            ) {
              inputRef.current.value += e.key;
              inputRef.current.dispatchEvent(new Event("keyup", { bubbles: true }));
            }
            break;
          }
        }
      }
    },
    [shortcutListFind, selected]
  );

  useEffect(() => {
    loadShortcutMessage({ filterByActive: true }).then(setShortcutList);
  }, []);

  useEffect(
    () => {
      if (shortcutListFind.length) {
        document.addEventListener("keyup", handleNavigateList);
      }
      return () => document.removeEventListener("keyup", handleNavigateList);
    },
    [shortcutListFind, handleNavigateList]
  );

  return (
    <Container>
      {show && <SCEmojiPicker show={show} onClose={() => setShow(false)} onSelect={addEmoji} />}
      <Popover
        open={shortcutListFind.length > 0}
        anchorEl={inputRef?.current}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        onClose={() => setShortcutListFind([])}
      >
        <SCList component="ul">
          {shortcutListFind.map((response, index) => (
            <ListItem key={response.id} selected={selected === index} onClick={() => replaceMessage(response)}>
              <b>{response.atalho}</b>
              <p>{response.mensagem}</p>
            </ListItem>
          ))}
        </SCList>
      </Popover>

      <Tooltip title="Emojis" placement="top-end" enterDelay={500}>
        <SentimentSatisfied onClick={() => setShow(true)} />
      </Tooltip>

      <textarea
        onKeyDown={handlePress}
        ref={inputRef}
        placeholder={intl.formatMessage({ defaultMessage: "Insira sua mensagem aqui" })}
        rows="2"
      />

      <Recorder onNewRecorder={file => onSendNewFile(file, setProgress)} />

      <Tooltip
        placement="top-end"
        enterDelay={500}
        title={
          progress !== 0
            ? `${intl.formatMessage({
                defaultMessage: "Carregando",
              })} ${progress}%`
            : intl.formatMessage({ defaultMessage: "Anexar arquivo" })
        }
      >
        <AttachFileButton uploading={progress !== 0}>
          {progress === 0 && <input onInput={handleUploadFile} ref={fileRef} type="file" />}
          <AttachFile style={{ transform: "rotate(-30deg)" }} />
          {progress !== 0 && <Spinner variant="static" value={progress} size={32} thickness={3.3} />}
        </AttachFileButton>
      </Tooltip>

      <Tooltip title={intl.formatMessage({ defaultMessage: "Enviar" })} enterDelay={500} placement="top-end">
        <Send onClick={handleSendMessage} />
      </Tooltip>
    </Container>
  );
}

InputData.propTypes = {
  onSendNewMessage: PropTypes.func.isRequired,
  onSendNewFile: PropTypes.func.isRequired,
};

export default InputData;
