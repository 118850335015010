import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";

import { Container } from "./styles";

function Disconnecting({ handleDisconnect }) {
  return (
    <Container>
      <div>
        <strong>
          <FormattedMessage defaultMessage="Desconectar da sua conta vai:" />
        </strong>
        <ul>
          <li>
            <FormattedMessage defaultMessage="Desativar as notificações" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Retirar acesso à lista de mensagens recentes" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Não será possível responder os Leads por meio do WhatsApp Business" />
          </li>
        </ul>
        <Button fullWidth variant="contained" color="primary" onClick={handleDisconnect}>
          <FormattedMessage defaultMessage="Desconectar" />
        </Button>
      </div>
    </Container>
  );
}

Disconnecting.propTypes = {
  handleDisconnect: PropTypes.func.isRequired,
};

export default Disconnecting;
