import React, { useState, useRef, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import MoreVert from "@material-ui/icons/MoreVert";
import Input from "@material-ui/icons/Input";
import PersonAdd from "@material-ui/icons/PersonAdd";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import ContactMail from "@material-ui/icons/ContactMail";

import { Tooltip, Popover, MenuList, Icon } from "@material-ui/core";
import { VIEWS } from "../constants";
import RegisterLead from "../../lead/components/cadastrar";
import { ActionButton } from "../../../components/ActionButton";

import { extractDDIPhone } from "../../../_common/utils/phone";
import { loadContacts, getChatIdentifier } from "../services";

import {
  Container,
  WhatsAppIcon,
  Contact,
  ArrowBackIcon,
  MenuButton,
  Actions,
  Content,
  SCLinearProgress,
  ChatBlube,
} from "./styles";

function Header({
  loading,
  canTransferChat,
  contact,
  handleClickBack,
  activeView,
  handleChangeView,
  handleOpenLeadDetails,
  handleNewHsmMessage,
  handleVincularContato,
}) {
  const intl = useIntl();
  const [showMenu, setShowMenu] = useState(false);
  const anchorRef = useRef(null);

  const renderContact = useCallback(contato => {
    const telefoneLead = getChatIdentifier({ telefoneLead: contato.telefoneLead });
    const nomeLead = contato.nomeLead || telefoneLead;
    if (contato.nomeLead && contato.contatos?.length) {
      const { nome } = contato.contatos[0];

      return (
        <Contact>
          <div title={nomeLead}>{nomeLead} </div>
          <p>
            {telefoneLead}{" "}
            {contato.contatos.length > 1 ? (
              <Tooltip
                placement="right-start"
                title={
                  <>
                    {contato.contatos.map(c => (
                      <p style={{ margin: 0 }} key={c.id}>
                        {c.nome}
                      </p>
                    ))}
                  </>
                }
              >
                <span>
                  ({contato.contatos.length} <FormattedMessage defaultMessage="contatos" />)
                </span>
              </Tooltip>
            ) : (
              <>
                {nome.length > 22 ? (
                  <Tooltip placement="right-start" title={nome}>
                    <span>{nome}</span>
                  </Tooltip>
                ) : (
                  <span>{nome}</span>
                )}
              </>
            )}
          </p>
        </Contact>
      );
    }

    return <div title={nomeLead}>{nomeLead}</div>;
  }, []);

  useEffect(
    () => {
      if (!contact?.id && [VIEWS.CHAT || VIEWS.TRANSFER_CHAT].includes(activeView)) {
        handleChangeView(VIEWS.CONTACTS);
      }
    },
    [contact]
  );

  return (
    <Container>
      {[VIEWS.CONTACTS, VIEWS.DISCONNECTED, VIEWS.INFO_TRANSFER_CHAT].includes(activeView) ? (
        <>
          <WhatsAppIcon />
          <Content>WhatsApp</Content>

          {VIEWS.CONTACTS === activeView && (
            <Tooltip title={<FormattedMessage defaultMessage="Nova conversa" />}>
              <ChatBlube onClick={handleNewHsmMessage} />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <ArrowBackIcon onClick={handleClickBack} />
          <Content>
            {activeView === VIEWS.DISCONNECTING && (
              <Content>
                <FormattedMessage defaultMessage="Desconectar conta" />
              </Content>
            )}
            {activeView === VIEWS.CHAT && <Content>{renderContact(contact)}</Content>}
            {activeView === VIEWS.END_CHAT && (
              <Content>
                <FormattedMessage defaultMessage="Ocultar chat" />
              </Content>
            )}
            {activeView === VIEWS.TRANSFER_CHAT && (
              <Content>
                <FormattedMessage defaultMessage="Transferir chat" />
              </Content>
            )}
          </Content>
        </>
      )}

      {[VIEWS.CONTACTS, VIEWS.CHAT].includes(activeView) && (
        <Actions>
          <MenuButton onClick={() => setShowMenu(true)}>
            <MoreVert />
          </MenuButton>
          <div ref={anchorRef} />

          <Popover
            open={showMenu}
            anchorEl={anchorRef?.current}
            keepMounted={false}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={() => setShowMenu(false)}
          >
            <MenuList style={{ padding: 0, width: 235 }}>
              {activeView === VIEWS.CONTACTS && (
                <ActionButton
                  type="menu"
                  icon={<Input />}
                  label={intl.formatMessage({ defaultMessage: "Desconectar conta" })}
                  onClick={() => {
                    setShowMenu(false);
                    handleChangeView(VIEWS.DISCONNECTING);
                  }}
                />
              )}

              {activeView === VIEWS.CHAT &&
                !contact.leadId && (
                  <RegisterLead
                    type="menu"
                    icon={<PersonAdd />}
                    label={intl.formatMessage({ defaultMessage: "Cadastrar Lead" })}
                    callback={leadId => {
                      setShowMenu(false);
                      if (leadId) loadContacts();
                    }}
                    initialFormValues={{
                      telefones: [
                        {
                          tipo: { id: 1 },
                          principal: true,
                          ...(() => {
                            const { ddi, phone: numero } = extractDDIPhone(contact.telefoneLead);
                            return { ddi, numero };
                          })(),
                        },
                      ],
                    }}
                  />
                )}

              {activeView === VIEWS.CHAT &&
                !contact.leadId && (
                  <ActionButton
                    type="menu"
                    icon={<Icon className="material-icons">badge</Icon>}
                    label={intl.formatMessage({ defaultMessage: "Vincular contato" })}
                    onClick={() => {
                      setShowMenu(false);
                      handleVincularContato();
                    }}
                  />
                )}

              {activeView === VIEWS.CHAT &&
                contact.leadId && (
                  <ActionButton
                    type="menu"
                    icon={<ContactMail />}
                    label={intl.formatMessage({ defaultMessage: "Detalhes do Lead" })}
                    onClick={() => {
                      setShowMenu(false);
                      handleOpenLeadDetails(contact);
                    }}
                  />
                )}

              {activeView === VIEWS.CHAT &&
                canTransferChat && (
                  <ActionButton
                    type="menu"
                    icon={<SwapHoriz />}
                    label={intl.formatMessage({ defaultMessage: "Transferir chat" })}
                    onClick={() => {
                      setShowMenu(false);
                      handleChangeView(VIEWS.TRANSFER_CHAT);
                    }}
                  />
                )}

              {activeView === VIEWS.CHAT &&
                contact && (
                  <ActionButton
                    type="menu"
                    icon={<VisibilityOff />}
                    label={intl.formatMessage({ defaultMessage: "Ocultar chat" })}
                    onClick={() => {
                      setShowMenu(false);
                      handleChangeView(VIEWS.END_CHAT);
                    }}
                  />
                )}
            </MenuList>
          </Popover>
        </Actions>
      )}
      {loading && <SCLinearProgress />}
    </Container>
  );
}

Header.propTypes = {
  loading: PropTypes.bool,
  canTransferChat: PropTypes.bool,
  contact: PropTypes.object,
  handleClickBack: PropTypes.func.isRequired,
  handleChangeView: PropTypes.func.isRequired,
  handleOpenLeadDetails: PropTypes.func.isRequired,
  handleNewHsmMessage: PropTypes.func.isRequired,
  activeView: PropTypes.string,
  handleVincularContato: PropTypes.func.isRequired,
};

export default Header;
