import styled from "styled-components";
import { DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

export const Title = styled(DialogTitle)`
  && {
    padding: 10px 26px;
    min-height: 60px;
    display: flex;
    align-items: center;
    position: relative;
  }
`;

export const Content = styled(DialogContent)`
  && {
    padding: 0 !important;
    border: 1px solid #d7d7d7;
    max-height: 486px;
  }
`;

export const MessageList = styled.ul`
  background: #f2f2f2;
  padding: 20px 10px 25px 10px;
  margin: 0;
  width: 100%;
  min-height: 200px;
  overflow-x: hidden;

  li:not(.sc-messagebox-separator) + li.sc-messagebox-separator {
    margin-top: 15px;
  }
  li.sc-messagebox-separator + li:not(.sc-messagebox-separator) {
    margin-top: 15px;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 3px;
  top: 7px;
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    position: relative;
    margin: 44px 0 2px;
    padding: 0;
    > div {
      > textarea {
        font-size: 14px;
      }
      > div {
        margin: 0 5px;
        > label {
          right: 68px;
          > svg {
            width: 23px;
            height: 23px;
          }
        }
        > svg {
          width: 25px;
          height: 25px;
        }
      }
    }
    section.emoji-mart {
      width: 390px !important;
    }
  }
`;

export const MessageContainer = styled.div`
  padding: 0px 20px 5px 25px;
  color: ${({ theme }) => theme.palette.text.darkSecondaryText};
  display: flex;
  flex-direction: column;

  > label {
    margin-top: 20px;
    > span + span {
      color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    }
  }
`;
