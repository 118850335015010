import React, { useRef, useEffect, useMemo, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dialog, DialogActions, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import InputData from "../Chat/InputData";
import ActionHSM from "../Chat/ActionHSM";
import MessageBox from "../Chat/MessageBox";

import { Title, MessageList, Content, CloseButton, StyledDialogActions, MessageContainer } from "./styles";
import { createDialogConfirm } from "../../../_common";
import { closeModalHistory } from "../../../store/reducers/whatsapp/actions";
import { addLabels } from "../../../store/reducers/whatsapp/selectors";
import { sendMessage, sendFile, markAsRead } from "../services";
import { newHsmMessage } from "../RegistrationRequest/services";
import { ORIGEM_MSG, STATUSMESSAGE } from "../constants";
import { extractDDIPhone } from "../../../_common/utils/phone";
import { useWhatsApp } from "../../../hooks";
import { eRequestStatus } from "../RegistrationRequest/constants";

function HistoryMessages({
  usuarioLogado,
  historico: { aberto, titulo, mensagens, permiteEnvioMsg, contato, somenteLeitura },
  closeModal,
}) {
  const intl = useIntl();
  const { statusSolicitacao } = useWhatsApp();
  const lastMessageRef = useRef(null);
  const [confirm, setConfirm] = useState({
    open: false,
    notShowAgain: false,
    onConfirm: () => {},
    onCancel: () => setConfirm(data => ({ ...data, open: false })),
  });

  const barraAcoesHabilitada = useMemo(() => statusSolicitacao !== eRequestStatus.cancelled && !somenteLeitura, [
    statusSolicitacao,
    somenteLeitura,
  ]);

  const messages = useMemo(
    () => {
      const msgs = addLabels(mensagens);

      if (barraAcoesHabilitada && msgs.length) {
        const lastMessage = msgs[msgs.length - 1];

        if (
          lastMessage.origem === ORIGEM_MSG.LEAD &&
          lastMessage.statusMessage !== STATUSMESSAGE.READ &&
          lastMessage.usuarioId !== 0 &&
          usuarioLogado.isGerente &&
          lastMessage.usuarioId !== usuarioLogado.id
        ) {
          lastMessage.exibeEncerrarChat = true;
        }
      }

      return msgs;
    },
    [mensagens]
  );

  const handleEndService = useCallback(telefoneLead => {
    createDialogConfirm({
      title: intl.formatMessage({ defaultMessage: "Confirmação" }),
      text: intl.formatMessage({
        defaultMessage:
          "Ao finalizar o atendimento, a notificação de mensagem não lida desta mensagem, não será enviada por e-mail para o responsável deste chat.",
      }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Finalizar" }),
      cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar" }),
      maxWidth: "sm",
      fullWidth: true,
      callback: accepted => accepted && markAsRead(telefoneLead),
    });
  }, []);

  useEffect(
    () => {
      setConfirm(data => ({ ...data, open: false, notShowAgain: false }));
    },
    [aberto]
  );

  useEffect(
    () => {
      setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView();
        }
      }, 100);
    },
    [messages]
  );

  function handleSendMessage(message) {
    const { usuarioId, telefoneLead } = contato;
    const { id } = usuarioLogado;

    if (contato.usuarioId && usuarioId !== id) {
      if (!confirm.open && !confirm.notShowAgain) {
        return setConfirm(data => ({
          ...data,
          open: true,
          onConfirm: () => {
            setConfirm(d => ({ ...d, open: false }));
            markAsRead(telefoneLead);
            sendMessage(message, contato);
          },
        }));
      }
    }

    markAsRead(telefoneLead);
    return sendMessage(message, contato);
  }

  function onSendNewFile(file, updateProgress) {
    const { usuarioId, telefoneLead } = contato;
    const { id } = usuarioLogado;

    if (contato.usuarioId && usuarioId !== id) {
      if (!confirm.open && !confirm.notShowAgain) {
        return setConfirm(data => ({
          ...data,
          open: true,
          onConfirm: () => {
            setConfirm(d => ({ ...d, open: false }));
            markAsRead(telefoneLead);
            sendFile(file, contato, updateProgress);
          },
        }));
      }
    }

    markAsRead(telefoneLead);
    return sendFile(file, contato, updateProgress);
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={aberto}>
        <Title>
          {titulo}
          <CloseButton onClick={closeModal}>
            <CloseIcon />
          </CloseButton>
        </Title>

        <Content>
          <MessageList>
            {messages.map(message => (
              <MessageBox
                key={message.id}
                message={message}
                isModal
                handleEndService={() => handleEndService(message.telefoneLead)}
              />
            ))}
            <span ref={lastMessageRef} />
          </MessageList>
        </Content>

        {barraAcoesHabilitada && (
          <StyledDialogActions>
            {permiteEnvioMsg ? (
              <InputData onSendNewMessage={handleSendMessage} onSendNewFile={onSendNewFile} />
            ) : (
              <ActionHSM
                handleSendHsm={() => {
                  const { ddi, phone } = extractDDIPhone(contato.telefoneLead);

                  newHsmMessage({
                    destinatario: {
                      id: contato.leadId,
                      leadId: contato.leadId,
                      nome: contato.nomeLead,
                      ddi,
                      telefone: phone,
                    },
                  });
                  closeModal();
                }}
                isModal
              />
            )}
          </StyledDialogActions>
        )}
      </Dialog>

      <Dialog fullWidth maxWidth="sm" open={confirm.open}>
        <Title>
          <FormattedMessage defaultMessage="Chat com responsável" />
        </Title>
        <MessageContainer>
          <span>
            <FormattedMessage defaultMessage="Ao enviar mensagem em um chat com responsável definido, você estará respondendo em nome dele. Deseja continuar?" />
          </span>
          <FormControlLabel
            label={<FormattedMessage defaultMessage="Não mostrar esta mensagem novamente, para este chat." />}
            control={
              <Checkbox
                color="primary"
                checked={confirm.notShowAgain}
                onClick={() => setConfirm(data => ({ ...data, notShowAgain: !data.notShowAgain }))}
              />
            }
          />
        </MessageContainer>
        <DialogActions>
          <Button color="default" onClick={confirm.onCancel}>
            <FormattedMessage defaultMessage="Cancelar" />
          </Button>
          <Button color="primary" onClick={confirm.onConfirm}>
            <FormattedMessage defaultMessage="Confirmar" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = state => ({
  historico: state.whatsApp.modais.historico,
  usuarioLogado: state.usuario,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModalHistory()),
});

HistoryMessages.propTypes = {
  usuarioLogado: PropTypes.object.isRequired,
  historico: PropTypes.shape({
    aberto: PropTypes.bool.isRequired,
    titulo: PropTypes.string.isRequired,
    contato: PropTypes.object.isRequired,
    mensagens: PropTypes.array.isRequired,
    permiteEnvioMsg: PropTypes.bool.isRequired,
    somenteLeitura: PropTypes.bool.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryMessages);
