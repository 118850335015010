import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { IconButton, ListItemIcon, ListItemText, Button, CircularProgress } from "@material-ui/core";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import CloseIcon from "@material-ui/icons/Close";

import createSnackbar from "../../../_common/utils/snackbar/createSnackbar";
import Tipografia from "../../../_common/components/Tipografia";
import Loading from "../../../_common/components/Loading";
import TextVariables from "../Shared/TextVariables";
import HSMVariable from "../Shared/TextVariables/HSMVariable/index";
import Recipient from "./Recipient";
import {
  Container,
  SCDialog,
  DialogTitle,
  Recipients,
  SelectMenu,
  SCList,
  SCListItem,
  EmptyContent,
  Content,
  Footer,
} from "./styles";

import { eVariableType } from "../RegistrationRequest/constants";
import { closeModalSendHSM } from "../../../store/reducers/whatsapp/actions";
import { loadTemplate } from "../RegistrationRequest/services";
import { sendHsmMessage } from "../services";

function ModalHSMSend({ envioHSM: { aberto, templates, destinatarios, destinatario }, handleClose }) {
  const intl = useIntl();
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [values, setValues] = useState({});
  const [recipient, setRecipient] = useState({});

  const handleSelectTemplate = useCallback(
    async (templateId, idLead) => {
      if (template?.id === templateId) return;
      setLoading(true);
      let leadId = idLead;

      if (!leadId && destinatarios?.length) {
        leadId = destinatarios.find(x => x.leadId)?.leadId;
      }

      const mappedVales = {};
      const data = await loadTemplate(templateId, leadId);

      if (Array.isArray(data?.variaveis)) {
        data.variaveis.forEach(variavel => {
          switch (variavel.tipo) {
            case eVariableType.fixed: {
              mappedVales[variavel.ordem] = variavel.valor || variavel.opcoes[0]?.valor || "";
              break;
            }
            case eVariableType.suggested: {
              mappedVales[variavel.ordem] = variavel.opcoes[0]?.valor || "";
              break;
            }
            default: {
              mappedVales[variavel.ordem] = variavel.valor || "";
            }
          }
        });
      }

      setTemplate(data);
      setLoading(false);
      setValues(mappedVales);
    },
    [template, destinatarios]
  );

  useEffect(
    () => {
      if (!aberto) {
        setTemplate(null);
        setValues({});
        setRecipient({});
      } else if (destinatario) {
        setRecipient(destinatario);
      }
    },
    [aberto]
  );

  const variables = useMemo(
    () => {
      const parsedVariables = {};

      if (template?.variaveis) {
        template.variaveis.forEach(variavel => {
          parsedVariables[variavel.ordem] = {
            component: HSMVariable,
            props: {
              value: values[variavel.ordem],
              key: variavel.ordem,
              type: variavel.tipo,
              label: variavel.rotulo,
              options: variavel.opcoes
                ?.map(x => ({ id: x.id, label: `(${x.rotulo})`, value: x.valor }))
                .filter(x => x.value),
              tip: variavel.dica,
              maxLength: variavel.totalCaracteres || 20,
            },
          };
        });
      }
      return parsedVariables;
    },
    [template, values]
  );

  const handleSendHSMMessage = useCallback(
    () => {
      const variblesTemplate = template.conteudo.match(/\{\{(.*?)\}\}/g) ?? [];
      const mensagem = variblesTemplate.reduce(
        (acc, cur) => acc.replace(cur, values[cur.replace(/([{}])/g, "")] ?? null),
        template.conteudo
      );
      const parsedValues = Object.keys(values)
        .map(key => ({ ordem: key, valor: values[key] }))
        .filter(x => x.valor);

      if (parsedValues.length < variblesTemplate.length) {
        createSnackbar(intl.formatMessage({ defaultMessage: "É necessário preencher todos os valores." }));
        return;
      } else if (parsedValues.length !== variblesTemplate.length) {
        createSnackbar(intl.formatMessage({ defaultMessage: "Há inconsistências encontradas no template." }));
        return;
      }

      setSending(true);

      sendHsmMessage(
        {
          templateId: template.id,
          mensagem,
          codNamespace: template.codNamespace,
          destinatario: recipient,
          variaveis: parsedValues,
        },
        () => {
          document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
        }
      )
        .then(handleClose)
        .finally(() => setSending(false));
    },
    [template, values, recipient]
  );

  return (
    <SCDialog open={aberto} fullWidth maxWidth="md" onBackdropClick={handleClose}>
      <Container>
        <DialogTitle>
          <div>
            <ChatBubbleIcon />
            <Tipografia tipo="textoSidebar">
              <FormattedMessage defaultMessage="Mensagem estruturada via WhatsApp Business" />
            </Tipografia>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Recipients>
          <Tipografia tipo="textoSidebar" cor="darkSecondaryText">
            <FormattedMessage defaultMessage="Para" />
          </Tipografia>
          <Recipient
            readOnly={!!destinatario?.telefone}
            recipient={recipient}
            options={destinatarios}
            onChange={setRecipient}
          />
        </Recipients>

        <SelectMenu>
          <Tipografia tipo="subtituloConteudo" cor="darkSecondaryText">
            {templates?.length ? (
              <FormattedMessage defaultMessage="Selecione o template que deseja utilizar" />
            ) : (
              <FormattedMessage defaultMessage="Nenhum template disponível" />
            )}
          </Tipografia>

          <SCList component="nav">
            {templates.map(t => (
              <SCListItem
                key={t.id}
                button
                selected={template?.id === t.id}
                onClick={() => handleSelectTemplate(t.id, recipient?.leadId)}
              >
                <ListItemIcon>
                  <ChatBubbleIcon />
                </ListItemIcon>
                <ListItemText primary={t.nome} />
              </SCListItem>
            ))}
          </SCList>
        </SelectMenu>

        <Content>
          {template && !loading ? (
            <>
              <header>
                <small>
                  <FormattedMessage defaultMessage="Nome" />
                </small>
                <Tipografia tipo="navbar" cor="darkPrimaryText">
                  {template?.nome}
                </Tipografia>
              </header>

              <div>
                <TextVariables
                  template={template?.conteudo ?? ""}
                  variables={variables}
                  onChange={({ key, params }) => setValues(x => ({ ...x, [key]: params }))}
                />
              </div>
            </>
          ) : (
            <EmptyContent>
              {loading ? (
                <Loading size={45} isLoading={loading} />
              ) : (
                <>
                  <ChatBubbleIcon />
                  <b>
                    <FormattedMessage defaultMessage="Selecione uma mensagem para visualizá-la" />
                  </b>
                </>
              )}
            </EmptyContent>
          )}
        </Content>

        <Footer>
          <Button onClick={handleClose}>
            <FormattedMessage defaultMessage="Cancelar" />
          </Button>
          <Button
            disabled={!template || !recipient?.telefone?.length || sending}
            color="primary"
            onClick={handleSendHSMMessage}
          >
            <FormattedMessage defaultMessage="Enviar" />
            {sending && <CircularProgress size={20} thickness={2} style={{ marginLeft: 12 }} />}
          </Button>
        </Footer>
      </Container>
    </SCDialog>
  );
}

ModalHSMSend.propTypes = {
  envioHSM: PropTypes.shape({
    aberto: PropTypes.bool.isRequired,
    templates: PropTypes.array.isRequired,
    destinatarios: PropTypes.array,
    destinatario: PropTypes.shape({
      nome: PropTypes.string,
      id: PropTypes.number,
      leadId: PropTypes.number,
      telefone: PropTypes.string,
    }),
  }),
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  envioHSM: state.whatsApp.modais.envioHSM,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeModalSendHSM()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalHSMSend);
