import styled from "styled-components";
import { List, ListItem, Chip, Tooltip } from "@material-ui/core";
import SelectDDI from "../../../lead/components/Telefones/SelectDDI";

export const Container = styled.div`
  display: flex;
  align-items: center;

  div::after,
  div::before {
    display: none;
  }

  input {
    font-size: 14px;
  }

  > span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    cursor: pointer;

    svg {
      transform: rotate(90deg);
      color: inherit;
      margin: 2px 0 0 5px;
    }
  }
`;

export const SCChip = styled(Chip)`
  && {
    background: #cccccc;
    height: 26px;
    font-size: 14px;
    > div {
      background: #5e5e5e;
      color: #fff;
      font-size: 14px;
      height: 27px;
      width: 27px;
    }
    > span {
      > div {
        display: flex;
        align-items: center;
        svg {
          margin: 0 0 0 9px;
          cursor: pointer;
          font-size: 19px;
        }
      }
    }
  }
`;

export const ScList = styled(List)`
  && {
    max-height: 150px;
    overflow-y: auto;
  }
`;

export const SCTooltip = styled(Tooltip)`
  && {
    background: #fff !important;
  }
`;

export const SCListItem = styled(ListItem)`
  && {
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;

    > span {
      display: block;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > p {
      margin: 0 15px;
    }

    &:hover {
      background: #f2f2f2;
    }
  }
`;

export const SCSelectDDI = styled(SelectDDI)`
  > button {
    margin-right: 0;
    > img {
      width: 23px;
    }
  }
`;

export const styles = () => ({
  htmlTooltip: {
    background: "#fff",
    border: "1px solid #dadde9",
    boxShadow: "2px 2px 6px -1px rgba(0, 0, 0, 0.28)",
    padding: 0,
    margin: 0,
  },
  htmlPopper: {
    background: "#fff",
    opacity: 1,
  },
});
