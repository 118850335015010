import axios from "axios";
import moment from "moment-timezone";
import { defineMessages } from "react-intl";
import env from "../src/../../environment";

import store from "../../store/store";
import {
  setStatus,
  setLoading,
  addMessage,
  setContatosLista,
  setMessages,
  setMessage,
  setMessagesAsRead,
  setSharedParams,
  openModalHistory,
  modalHistoryAddMessage,
  modalHistorySetMessage,
} from "../../store/reducers/whatsapp/actions";

import { extractDDIPhone, formatPhoneMask } from "../../_common/utils/phone";
import { objectKeysToCamelCase } from "../../_common/utils/string";
import createSnackBar from "../../_common/utils/snackbar/createSnackbar";
import { MESSAGETYPES, ORIGEM_MSG, STATUS, STATUSMESSAGE } from "./constants";
import { invalidarCacheUsuarioLogado } from "../../store/reducers/usuario/usuarioActions";
import { appIntl } from "../../_common/containers/ConnectedIntlProvider";
import { AjaxBlackout } from "../../_common";
import {
  errorResponseInterceptor,
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
} from "../../_common/utils/api/interceptors";
import localStorageManager from "../../_common/utils/storage";
import { INFOS_USUARIO } from "../../_common/utils/storage/constantesStorage";

const messages = defineMessages({
  statusWpp: { defaultMessage: "Erro ao alterar status do WhatsApp" },
  contactList: { defaultMessage: "Erro ao carregar lista de contatos." },
  messages: { defaultMessage: "Erro ao carregar mensagens." },
  sendMessage: { defaultMessage: "Ocorreu um problema e a mensagem não foi enviada." },
  tranferChat: { defaultMessage: "Erro ao Transferir chat." },
  hiddenChat: { defaultMessage: "Erro ao ocultar chat." },
  showHistoryChat: { defaultMessage: "Erro ao consultar histórico do chat." },
  chatList: { defaultMessage: "Erro ao carregar lista de chats." },
  sendHsmMessage: { defaultMessage: "Erro ao enviar mensagem. Motivo: {message}" },
  errorNewHsm: {
    defaultMessage:
      "Você não pode enviar mensagem para o número informado, ele já possui uma conversa iniciada e/ou pertence a outro pré-vendedor.",
  },
});

const api = axios.create({});

api.defaults.baseURL = env.REACT_APP_BASE_URL;
api.interceptors.response.use(responseInterceptor, errorResponseInterceptor);
api.interceptors.request.use(requestInterceptor, requestInterceptorError);

api.interceptors.response.use(
  response => {
    if (response.data?.success === false) return Promise.reject(new Error(response.data?.msg || "Desconhecido"));
    return { ...response, data: objectKeysToCamelCase(response.data) };
  },
  error => Promise.reject(error)
);

export { api };

export async function changeStatus(status) {
  try {
    store.dispatch(setLoading(true));
    await api.post(`/api/pipeline/whatsapp/alterarstatus?status=${status}`);
    invalidarCacheUsuarioLogado();

    const usuarioStorage = localStorageManager.getItem(INFOS_USUARIO);
    const { whatsApp } = usuarioStorage;
    whatsApp.status = status;
    localStorageManager.setItem(INFOS_USUARIO, usuarioStorage);

    store.dispatch(setStatus(status));
  } catch (err) {
    createSnackBar(messages.statusWpp);
  } finally {
    store.dispatch(setLoading(false));
  }
}

export async function loadContacts() {
  const {
    usuario: { isGerente },
  } = store.getState();

  try {
    store.dispatch(setLoading(true));
    const { data } = await api.get(`/api/pipeline/whatsapp/listar?perfilGerente=${isGerente}`);
    if (Array.isArray(data)) {
      store.dispatch(setContatosLista(data));
    }
  } catch (err) {
    createSnackBar(messages.contactList);
  } finally {
    store.dispatch(setLoading(false));
  }
}

export async function loadMessages({ telefoneLead, usuarioId }) {
  try {
    store.dispatch(setLoading(true));
    const { data } = await api.get("/api/pipeline/whatsapp/listarporcontato", {
      params: { usuarioId, telefoneLead },
    });

    if (Array.isArray(data)) {
      store.dispatch(setMessages(data));

      if (data.some(msg => msg.origem === ORIGEM_MSG.LEAD && msg.statusMessage !== STATUSMESSAGE.READ)) {
        markAsRead(telefoneLead);
      }
    }
  } catch (err) {
    createSnackBar(messages.messages);
  } finally {
    store.dispatch(setLoading(false));
  }
}

export async function markAsRead(telefoneLead) {
  const {
    whatsApp: { codigoCampanha },
  } = store.getState();

  await api.post(
    `/api/pipeline/whatsapp/visualizarmensagens?telefoneLead=${telefoneLead}&codigoCampanha=${codigoCampanha}`
  );

  store.dispatch(setMessagesAsRead(telefoneLead));
}

export function updateMessageReadWithSocket({ telefoneLead }) {
  if (telefoneLead) store.dispatch(setMessagesAsRead(telefoneLead));
}

export async function sendMessage(msg, contact) {
  const {
    usuario: { id, empresaClienteId, timezone },
    whatsApp: { codigoCampanha },
  } = store.getState();

  try {
    const newMessage = {
      empresaClienteId,
      mensagem: msg,
      ciclo: contact.ciclo,
      nomeLead: contact.nomeLead,
      telefoneLead: contact.telefoneLead,
      telefoneEmpresa: contact.telefoneEmpresa,
      leadId: contact.leadId,
      usuarioId: contact.usuarioId,
      usuarioEnvioMensagemId: id,
      codigoCampanha,
      statusMessage: STATUSMESSAGE.SENT,
      tipo: MESSAGETYPES.TEXT,
      origem: ORIGEM_MSG.SPOTTER,
      timezoneId: timezone,
    };

    const { data } = await api.post("/api/pipeline/whatsapp/enviarmensagem", newMessage);

    if (!data) createSnackBar(messages.sendMessage);
  } catch (err) {
    createSnackBar(messages.sendMessage);
  }
}

export function receiveMessageWithSocket(msg) {
  const {
    whatsApp: {
      idChatAberto,
      contatos,
      status,
      modais: { historico },
    },
    usuario: { id, isGerente },
  } = store.getState();

  const message = objectKeysToCamelCase(msg);

  if (status === STATUS.CONNECTED) {
    if (contatos[message.telefoneLead]) {
      store.dispatch(addMessage(message.telefoneLead, message));
      if (message.origem === ORIGEM_MSG.LEAD && message.telefoneLead === idChatAberto) {
        markAsRead(message.telefoneLead);
      }
    } else {
      loadContacts();
    }
  }

  if (historico.aberto && historico.contato?.telefoneLead === message.telefoneLead) {
    if (!historico.somenteLeitura && !historico.permiteEnvioMsg) {
      const chatTimeLeft = moment(new Date()).diff(moment(message.dateTimezone, "DD/MM/YYYY HH:mm:ss"), "hours");
      const canSendMessages =
        (isGerente || message.usuarioId === id) && chatTimeLeft < 24 && message.origem === ORIGEM_MSG.LEAD;

      store.dispatch(modalHistoryAddMessage(message, canSendMessages));
    } else {
      store.dispatch(modalHistoryAddMessage(message, historico.permiteEnvioMsg));
    }

    if (
      message.origem === ORIGEM_MSG.LEAD &&
      historico.contato?.telefoneLead === message.telefoneLead &&
      (message.usuarioId === id || !message.usuarioId)
    ) {
      markAsRead(message.telefoneLead);
    }
  }
}

export function updateMessageWithSocket(msg) {
  const {
    whatsApp: {
      contatos,
      status,
      modais: { historico },
    },
  } = store.getState();

  const message = objectKeysToCamelCase(msg);

  if (status === STATUS.CONNECTED && contatos[message.telefoneLead]) {
    store.dispatch(setMessage(message.telefoneLead, message));
  }

  if (historico.aberto && historico.contato?.telefoneLead === message.telefoneLead) {
    store.dispatch(modalHistorySetMessage(message));
  }
}

export async function transferChat({ telefoneLead, leadId, usuarioId, chatId }, user, transferLead) {
  const {
    usuario: { empresaClienteId, id },
    whatsApp: { codigoCampanha },
  } = store.getState();

  const data = {
    telefoneLead,
    leadId,
    empresaClienteId,
    codigoCampanha,
    usuarioDestinoId: user.id,
    usuarioId: id,
    transferirLead: transferLead,
    usuarioResponsavelId: usuarioId,
    chatId,
  };

  try {
    store.dispatch(setLoading(true));
    await api.post(`/api/pipeline/whatsapp/transferirchat`, data);
    await store.dispatch(setSharedParams({ user }));
  } catch (err) {
    createSnackBar(messages.tranferChat);
  } finally {
    store.dispatch(setLoading(false));
  }
}

export async function endChat({ telefoneLead, leadId }, observacoes = "") {
  const {
    whatsApp: { codigoCampanha },
  } = store.getState();

  try {
    store.dispatch(setLoading(true));
    await api.post(
      `/api/pipeline/whatsapp/encerrarchat?telefoneLead=${telefoneLead}
      &codigoCampanha=${codigoCampanha}&observacoes=${observacoes}&leadId=${leadId}`
    );

    await loadContacts();
  } catch (err) {
    createSnackBar(messages.hiddenChat);
  } finally {
    store.dispatch(setLoading(false));
  }
}

export async function showHistory({ leadId, telefoneLead }) {
  const {
    usuario: { isGerente, id },
  } = store.getState();

  try {
    AjaxBlackout.Show();
    const { data: mensagens } = await api.get(
      `/api/pipeline/whatsapp/listarhistorico?leadId=${leadId}&telefoneLead=${telefoneLead}`
    );

    let contact = mensagens.filter(msg => msg.origem === ORIGEM_MSG.LEAD)?.pop() ?? {};
    if (!contact.id) contact = mensagens[mensagens.length - 1] ?? {};

    const hasUnreadMessages = mensagens.some(
      msg => msg.origem === ORIGEM_MSG.LEAD && msg.statusMessage !== STATUSMESSAGE.READ
    );
    const chatTimeLeft = moment(new Date()).diff(moment(contact.dateTimezone, "DD/MM/YYYY HH:mm:ss"), "hours");
    const canSendMessages =
      (isGerente || contact.usuarioId === id) && chatTimeLeft < 24 && contact.origem === ORIGEM_MSG.LEAD;

    const nameLead = contact.nomeLead ? `${contact.nomeLead} | ` : "";
    const phoneLead = getChatIdentifier({ telefoneLead: contact.telefoneLead });

    const countContacts = contact.contatos?.length;
    let contactsLead = "";

    if (countContacts)
      contactsLead = ` (${countContacts > 1 ? `${countContacts} Contatos` : contact.contatos[0].nome})`;

    const title = `${nameLead}${phoneLead}${contactsLead}`;

    if (hasUnreadMessages && (contact.usuarioId === id || !contact.usuarioId)) markAsRead(telefoneLead);

    store.dispatch(openModalHistory(title, mensagens, contact, canSendMessages, false));
  } catch (err) {
    createSnackBar(messages.showHistoryChat);
  } finally {
    AjaxBlackout.Hide();
  }
}

export async function loadChats({
  currentPage = 1,
  nomeTelefoneLead = "",
  statusChat = [],
  usuarioId = 0,
  isPreVendedor = false,
}) {
  const {
    usuario: { empresaClienteId, timezone },
  } = store.getState();

  try {
    const { data } = await api.post(`/api/pipeline/whatsapp/listagemchat?currentPage=${currentPage}`, {
      empresaClienteId,
      timezoneId: timezone,
      nomeTelefoneLead,
      statusChat,
      usuarioId,
      isPreVendedor,
    });
    if (Array.isArray(data?.data)) {
      return data;
    }
  } catch (err) {
    createSnackBar(messages.chatList);
  }
  return [];
}

export function showLeadDetails({ leadId }) {
  const url = `/spotter/detalhes-lead/${leadId}`;
  window.open(url, "_blank");
}

export async function sendFile(file, contact, updateProgress = () => {}) {
  const intl = appIntl();
  const {
    usuario: { empresaClienteId, id, timezone },
    whatsApp: { codigoCampanha },
  } = store.getState();

  const maxFileSizeInBytes = 5242880; // 5MB
  const allowedExtensions = [
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx",
    "pdf",
    "jpeg",
    "jpg",
    "png",
    "gif",
    "bmp",
    "webp",
    "aac",
    "m4a",
    "amr",
    "mp3",
    "ogg",
    "opus",
    "mp4",
    "3gp",
  ];
  try {
    const config = {
      onUploadProgress(progressEvent) {
        updateProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total) - 1);
      },
    };

    const extension = file.name
      .split(".")
      .pop()
      .toLowerCase();

    if (file.size > maxFileSizeInBytes)
      throw new Error(intl.formatMessage({ defaultMessage: "Tamanho máximo de 5mb excedido." }));
    if (!allowedExtensions.includes(extension))
      throw new Error(intl.formatMessage({ defaultMessage: "Tipo de arquivo não permitido." }));

    const message = {
      empresaClienteId,
      ciclo: contact.ciclo,
      nomeLead: contact.nomeLead,
      telefoneLead: contact.telefoneLead,
      telefoneEmpresa: contact.telefoneEmpresa,
      leadId: contact.leadId,
      usuarioId: contact.usuarioId,
      usuarioEnvioMensagemId: id,
      codigoCampanha,
      statusMessage: STATUSMESSAGE.SENT,
      origem: ORIGEM_MSG.SPOTTER,
      timezoneId: timezone,
    };

    switch (file.type.split("/")[0]) {
      case "image":
        message.tipo = MESSAGETYPES.IMAGE;
        break;
      case "audio":
        message.tipo = MESSAGETYPES.AUDIO;
        break;
      case "video":
        message.tipo = MESSAGETYPES.VIDEO;
        break;
      case "application":
      case "document":
        message.tipo = MESSAGETYPES.DOCUMENT;
        break;
      default:
        throw new Error(intl.formatMessage({ defaultMessage: "Tipo de arquivo não permitido." }));
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("message", JSON.stringify(message));

    const { data } = await api.post(`/api/pipeline/whatsapp/enviarmedia`, formData, config);

    if (!data) createSnackBar(messages.sendMessage);

    return data;
  } catch (err) {
    createSnackBar(
      intl.formatMessage(
        {
          defaultMessage: "Erro ao enviar arquivo. Motivo: {motivo}",
        },
        { motivo: err.message }
      )
    );
    throw err;
  } finally {
    updateProgress(0);
  }
}

export function updateClientStatusSocket({ status }) {
  invalidarCacheUsuarioLogado();
  store.dispatch(setStatus(status));
}

export const sendHsmMessage = async ({ templateId, codNamespace, mensagem, variaveis, destinatario }, callback) => {
  const intl = appIntl();
  const {
    usuario: { id, empresaClienteId, timezone, isGerente },
    whatsApp: { codigoCampanha, telefone },
  } = store.getState();

  try {
    const telefoneLead = `${destinatario.ddi}${destinatario.telefone}`;
    const idLead = destinatario.leadId;
    const contact = await searchInfoContact({ telefone: telefoneLead, leadId: idLead });

    const newMessage = {
      empresaClienteId,
      mensagem,
      variaveis,
      templateId,
      codNamespace,
      codigoCampanha,
      telefoneLead,
      usuarioEnvioMensagemId: id,
      statusMessage: STATUSMESSAGE.SENT,
      tipo: MESSAGETYPES.TEXT,
      origem: ORIGEM_MSG.SPOTTER,
      timezoneId: timezone,
      telefoneEmpresa: telefone,
      nomeLead: null,
      leadId: null,
      usuarioId: null,
    };

    if (contact.leadId) {
      if (contact.preVendedorId === id) {
        if (contact.possuiChat) {
          if (contact.usuarioId === id) {
            newMessage.usuarioId = contact.usuarioId;
            newMessage.leadId = contact.leadId;
            newMessage.nomeLead = contact.nomeLead;
          } else {
            throw new Error(intl.formatMessage(messages.errorNewHsm));
          }
        } else {
          newMessage.usuarioId = contact.preVendedorId;
          newMessage.leadId = contact.leadId;
          newMessage.nomeLead = contact.nomeLead;
        }
      } else if (contact.possuiChat) {
        if (contact.usuarioId === id || isGerente) {
          newMessage.usuarioId = contact.usuarioId;
          newMessage.leadId = contact.leadId;
          newMessage.nomeLead = contact.nomeLead;
        } else {
          throw new Error(intl.formatMessage(messages.errorNewHsm));
        }
      } else if (isGerente) {
        newMessage.usuarioId = id;
        newMessage.leadId = contact.leadId;
        newMessage.nomeLead = contact.nomeLead;
      } else {
        throw new Error(intl.formatMessage(messages.errorNewHsm));
      }
    } else if (contact.possuiChat) {
      if (contact.usuarioId === id || isGerente) {
        newMessage.usuarioId = contact.usuarioId;
      } else {
        throw new Error(intl.formatMessage(messages.errorNewHsm));
      }
    } else {
      newMessage.usuarioId = id;
    }

    const { data } = await api.post("/api/pipeline/whatsapp/enviarmensagem", newMessage);

    if (!data) createSnackBar(messages.sendMessage);

    if (callback) callback();
    return null;
  } catch ({ message }) {
    createSnackBar(intl.formatMessage(messages.sendHsmMessage, { message }));
    return Promise.reject(message);
  }
};

export async function searchInfoContact({ telefone, leadId }) {
  const {
    usuario: { empresaClienteId },
  } = store.getState();

  const contact = {
    empresaClienteId,
    telefone,
    usuarioId: null,
    leadId,
    preVendedorId: null,
    nomeLead: null,
    possuiChat: false,
  };

  try {
    const { data } = await api.get("/api/pipeline/whatsapp/obterinformacoeschat", {
      params: {
        empresaClienteId,
        telefone,
        leadId,
      },
    });

    if (data) Object.assign(contact, data);

    return contact;
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function showHistoryPublicLink({ leadId, telefoneLead, empresaClienteId, timezone }) {
  try {
    AjaxBlackout.Show();
    const { data: mensagens } = await api.post(`/public/whatsapp/listarhistorico`, {
      leadId,
      empresaClienteId,
      timezoneId: timezone,
      nomeTelefoneLead: telefoneLead,
    });

    let contact = mensagens.filter(msg => msg.origem === ORIGEM_MSG.LEAD)?.pop();
    if (!contact) contact = mensagens.filter(msg => msg)?.pop() ?? {};

    const nameLead = contact.nomeLead ? `${contact.nomeLead} | ` : "";
    const phoneLead = getChatIdentifier({ telefoneLead: contact.telefoneLead });

    const title = `${nameLead}${phoneLead}`;

    store.dispatch(openModalHistory(title, mensagens, contact, false, true));
  } catch ({ message }) {
    createSnackBar(`Erro ao consultar Histórico. Motivo: "${message}"`);
  } finally {
    AjaxBlackout.Hide();
  }
}

export function getChatIdentifier({ nomeLead, telefoneLead }) {
  if (nomeLead) return nomeLead;
  const { ddi, phone } = extractDDIPhone(telefoneLead);
  return formatPhoneMask({ ddi, phone, showDDI: true });
}

export async function VincularContatoWppAoLead(dadosLead, dadosChat, transferirChat) {
  AjaxBlackout.Show();
  const data = {
    telefoneLead: dadosChat.telefoneLead,
    leadId: dadosLead.id,
    nomeLead: dadosLead.descricao,
    chatId: dadosChat.chatId,
    transferirChat,
    usuarioId: dadosChat.usuarioId,
  };

  try {
    store.dispatch(setLoading(true));
    await api.post(`/api/pipeline/whatsapp/VincularContatoWpp`, data);
    await loadContacts();
  } catch (err) {
    createSnackBar(messages.vincularContato);
  } finally {
    store.dispatch(setLoading(false));
    AjaxBlackout.Hide();
  }
}

export async function VincularContatoWppAoContatoDoLead(dadosContatoLead, dadosChat, transferirChat) {
  AjaxBlackout.Show();
  const data = {
    telefoneLead: dadosChat.telefoneLead,
    leadId: dadosContatoLead.leadId,
    nomeLead: dadosContatoLead.nomeLead,
    chatId: dadosChat.chatId,
    contatoId: dadosContatoLead.contatoId,
    transferirChat,
    usuarioId: dadosChat.usuarioId,
  };

  try {
    store.dispatch(setLoading(true));
    await api.post(`/api/pipeline/whatsapp/VincularContatoWpp`, data);
    await loadContacts();
  } catch (err) {
    createSnackBar(messages.vincularContato);
  } finally {
    store.dispatch(setLoading(false));
    AjaxBlackout.Hide();
  }
}
