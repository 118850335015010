import React, { useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import { TextField, Popover, Avatar, ListItem, ListItemText, Tooltip, withStyles } from "@material-ui/core";

import { getIniciaisDeUmNome } from "../../../../_common/utils/string";
import { formatPhoneMask } from "../../../../_common/utils/phone";
import { Container, ScList, SCListItem, SCChip, SCSelectDDI, styles } from "./styles";
import TextMaskTelefone from "../../../../components/TextMaskTelefone";

function Recipient({ recipient, readOnly, options, onChange, classes }) {
  const intl = useIntl();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const contacts = useMemo(
    () =>
      options?.map(option => ({
        ...option,
        formatedPhone: formatPhoneMask({ ddi: option.ddi, phone: option.telefone, showDDI: true }),
      })) ?? [],
    [options]
  );

  const chipContact = useMemo(
    () => ({
      ...recipient,
      formatedPhone: formatPhoneMask({ ddi: recipient.ddi, phone: recipient?.telefone, showDDI: true }),
      initials: getIniciaisDeUmNome(recipient?.nome || ""),
    }),
    [recipient]
  );

  return (
    <Container>
      {options?.length || (recipient?.nome && recipient?.leadId) ? (
        <>
          {recipient?.telefone ? (
            <Tooltip
              enterDelay={400}
              classes={{
                tooltip: classes.htmlTooltip,
                popper: classes.htmlPopper,
              }}
              title={
                <ListItem style={{ padding: "6px 20px", minWidth: 230 }}>
                  <Avatar>{chipContact.initials}</Avatar>
                  <ListItemText primary={chipContact.nome} secondary={chipContact.formatedPhone} />
                </ListItem>
              }
            >
              <SCChip
                avatar={<Avatar>{chipContact.initials}</Avatar>}
                label={
                  <div>
                    {chipContact.nome}
                    {!readOnly && <CloseIcon onClick={() => onChange({})} />}
                  </div>
                }
              />
            </Tooltip>
          ) : (
            <span ref={anchorRef} onClick={() => setOpen(true)}>
              <FormattedMessage defaultMessage="Destinatário" />
              <ChevronRightIcon />
            </span>
          )}
        </>
      ) : (
        <>
          <SCSelectDDI value={recipient?.ddi} onChange={ddi => onChange(x => ({ ...x, ddi }))} />
          <TextField
            fullWidth
            placeholder={intl.formatMessage({ defaultMessage: "Destinatário (número)" })}
            value={recipient?.telefone ?? ""}
            onChange={({ target }) => onChange(x => ({ ...x, telefone: target.value.replace(/\D/g, "") }))}
            InputProps={{
              inputComponent: TextMaskTelefone,
              inputProps: { ddi: recipient?.ddi },
            }}
          />
        </>
      )}

      <Popover
        open={open}
        anchorEl={anchorRef?.current}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setOpen(false)}
      >
        <ScList component="ul">
          {contacts.map(contact => (
            <SCListItem
              component="li"
              key={contact.id}
              onClick={() => {
                onChange(contact);
                setOpen(false);
              }}
            >
              <span>{contact.nome}</span>
              <p>{contact.formatedPhone}</p>
            </SCListItem>
          ))}
        </ScList>
      </Popover>
    </Container>
  );
}

Recipient.propTypes = {
  recipient: PropTypes.object,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Recipient);
