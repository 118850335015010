import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

export default function Badge({ children, counter }) {
  return (
    <Container>
      {counter > 0 && <span>{counter}</span>}
      {children}
    </Container>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  counter: PropTypes.number,
};
