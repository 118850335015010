import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Container, Input, StyledButton } from "./styles";

function EndChat({ handleEnd }) {
  const intl = useIntl();
  const [comments, setComments] = useState("");

  return (
    <Container>
      <div>
        <strong>
          <FormattedMessage defaultMessage="Ao ocultar o chat, você:" />
        </strong>
        <ul>
          <li>
            <FormattedMessage defaultMessage="Ainda receberá notificações de novas mensagens deste chat" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Terá o chat disponível na Lista de chats (Telefonia > Chats)" />
          </li>
          <li>
            <FormattedMessage defaultMessage="No caso deste Lead enviar nova mensagem, o chat será exibido novamente nesta janela." />
          </li>
        </ul>

        <Input
          placeholder={intl.formatMessage({ defaultMessage: "Observações" })}
          value={comments}
          onChange={({ target }) => setComments(target.value)}
          variant="outlined"
          fullWidth
          multiline
          rowsMax="3"
          inputProps={{ maxLength: 200 }}
        />

        <StyledButton fullWidth variant="contained" color="primary" onClick={() => handleEnd(comments.trim())}>
          <FormattedMessage defaultMessage="Ocultar" />
        </StyledButton>
      </div>
    </Container>
  );
}

EndChat.propTypes = {
  handleEnd: PropTypes.func.isRequired,
};

export default EndChat;
