import styled from "styled-components";
import { List, ListItem, Dialog } from "@material-ui/core";

export const SCDialog = styled(Dialog)`
  > div[role="document"] > div {
    overflow-y: unset;
    max-height: unset;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    "title title"
    "header header"
    "menu content"
    "menu footer";
  grid-template-columns: 320px 1fr;
  grid-template-rows: 48px 48px 1fr 56px;
  height: 100vh;
  max-height: 580px;
`;

export const DialogTitle = styled.div`
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #394554;
  padding: 0 4px 0 15px;

  div {
    display: flex;
    align-items: flex-start;

    > svg {
      margin-right: 12px;
      font-size: 17px;
    }
  }

  svg,
  span {
    color: #fff;
  }
`;

export const Recipients = styled.header`
  grid-area: header;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  width: 100%;

  > span {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px 0 25px;
    border-right: 1px solid #e4e4e4;
    margin-right: 18px;
  }
`;

export const SelectMenu = styled.div`
  grid-area: menu;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e4e4e4;

  > span {
    padding: 15px 15px 8px;
  }

  > nav {
    > div > div > span {
      font-size: 14px;
    }
    svg {
      font-size: 17px;
      margin: 0;
    }
  }
`;

export const SCList = styled(List)`
  && {
    height: 100%;
    overflow-y: auto;
  }
`;

export const SCListItem = styled(ListItem)`
  && {
    background: ${({ selected }) => (selected ? "#F2F2F2" : "transparent")};
  }
`;

export const EmptyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background: #f7f7f7;

  > svg {
    margin-bottom: 15px;
    font-size: 75px;
    color: #727272;
  }
  > b {
    font-size: 16px;
    opacity: 0.8;
  }
`;

export const Content = styled.main`
  grid-area: content;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  header {
    border-bottom: 1px solid #f2f2f2;
    padding: 10px;
    > span {
      display: block;
      padding-top: 5px;
    }
  }

  > div {
    padding: 15px;
    color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 32px;
  }
`;

export const Footer = styled.footer`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px 0;
  border-top: 1px solid #e4e4e4;

  button {
    margin-right: 15px;
    &:first-of-type {
      color: #919191;
    }
  }
`;
