import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import MicRecorder from "mic-recorder-to-mp3";

import Mic from "@material-ui/icons/Mic";
import SendIcon from "@material-ui/icons/Send";

import { Tooltip } from "@material-ui/core";
import moment from "moment";

import createSnackBar from "../../../../../_common/utils/snackbar/createSnackbar";
import { Container, SCIconButton } from "./styles";

const MaxDurationInSeconds = 240;

function Recorder({ onNewRecorder }) {
  const intl = useIntl();

  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState(MaxDurationInSeconds);

  const recorderRef = useRef({});

  const timeLeft = useMemo(() => moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format("mm:ss"), [
    duration,
  ]);

  useEffect(
    () => {
      if (duration <= 0) stopRecorder(true);
    },
    [duration]
  );

  const stopRecorder = useCallback(
    async hasSendResult => {
      if (recorderRef.current.mediaRecorder && !hasSendResult) {
        recorderRef.current.mediaRecorder.stop();
      }

      if (recorderRef.current.mediaRecorder && hasSendResult) {
        try {
          const [buffer] = await recorderRef.current.mediaRecorder.stop().getMp3();
          const file = new File(buffer, `$AUDIO-${new Date().getTime()}.mp3`, { type: "audio/mpeg" });
          onNewRecorder(file);
        } catch ({ message }) {
          createSnackBar(
            intl.formatMessage({ defaultMessage: "Erro ao capturar audio. Motivo: {message}" }, { message })
          );
        }
      }

      setIsRecording(false);
      setDuration(MaxDurationInSeconds);
      clearInterval(recorderRef.current?.timer);
    },
    [recorderRef]
  );

  const handleRecordAudio = useCallback(
    async () => {
      if (isRecording) {
        stopRecorder(true);
        return;
      }

      try {
        recorderRef.current.mediaRecorder = new MicRecorder({ bitRate: 128 });

        await recorderRef.current.mediaRecorder.start();
        setIsRecording(true);

        recorderRef.current.timer = setInterval(() => {
          setDuration(x => x - 1);
        }, 1000);
      } catch ({ message }) {
        stopRecorder(false);
        createSnackBar(
          intl.formatMessage({ defaultMessage: "Erro ao capturar audio. Motivo: {message}" }, { message })
        );
      }
    },
    [recorderRef, isRecording]
  );

  return (
    <Container isRecording={isRecording}>
      <div>
        <Tooltip
          placement="top-end"
          title={
            isRecording
              ? intl.formatMessage({ defaultMessage: "Gravando" })
              : intl.formatMessage({ defaultMessage: "Gravar" })
          }
          enterDelay={500}
        >
          <Mic onClick={() => !isRecording && handleRecordAudio()} />
        </Tooltip>
        {isRecording && <span>{timeLeft}</span>}
      </div>
      {isRecording && (
        <div>
          <button onClick={() => stopRecorder(false)}>
            <FormattedMessage defaultMessage="Cancelar" />
          </button>
          <Tooltip placement="top-end" title={intl.formatMessage({ defaultMessage: "Enviar" })} enterDelay={500}>
            <SCIconButton onClick={() => stopRecorder(true)}>
              <SendIcon />
            </SCIconButton>
          </Tooltip>
        </div>
      )}
    </Container>
  );
}

Recorder.propTypes = {
  onNewRecorder: PropTypes.func.isRequired,
};

export default Recorder;
