import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 25px 5px 25px;

    strong {
      font-size: 15px;
    }

    > ul {
      padding-left: 20px;
      margin-bottom: -5px;
      li {
        margin: 9px 0;
        color: #b1b1b1;
      }
    }
  }
`;

export const Input = styled(TextField)`
  margin: 15px 0px;
  background: #f2f2f2;
  textarea {
    font-size: 13px;
    padding: 0 3px;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 182px;
  margin: 0 auto;
`;
