import styled from "styled-components";
import { CircularProgress, List } from "@material-ui/core";
import EmojiPicker from "../../EmojiPicker";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  background: #fff;

  textarea {
    resize: none;
    width: 100%;
    height: 90%;
    padding: 3px 25px 3px 3px;
    margin: 0 5px;
    border-color: #e1e1e1;
    border-radius: 3px;
    font-size: 13px !important;
    line-height: 16px !important;
    outline: none;
    overflow-y: auto;
    font: inherit;

    &::-webkit-scrollbar {
      width: 5px;
      height: 12px;
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 1px solid #eee;
      &:hover {
        background-color: #9d9d9d;
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
    color: #8e8e8e;
    cursor: pointer;
  }
`;

export const AttachFileButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  opacity: ${({ uploading }) => (uploading ? 0.5 : 1)};
  margin: 0 5px 0 1px;

  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
`;

export const SCEmojiPicker = styled(EmojiPicker)`
  position: absolute;
  bottom: 39px;
  left: 4px;
  background: #fff;
`;

export const Spinner = styled(CircularProgress)`
  position: absolute;
  right: 21px;
  top: -1px;
  z-index: 0;
`;

export const SCList = styled(List)`
  && {
    max-height: 150px;
    overflow-y: auto;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 380px;
  background: ${({ selected }) => (selected ? "#f2f2f2 !important" : "none")};
  b {
    font-weight: 500;
    margin-right: 10px;
  }
  > p {
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  &:hover {
    background: #f9f9f9;
  }
`;
