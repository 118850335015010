import styled, { css } from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import Person from "@material-ui/icons/Person";
import TextDecoration from "../../Shared/TextDecoration";

export const Container = styled.li`
  display: flex;
  flex-direction: "column";
  width: 100%;
  list-style: none;

  &:first-child {
    margin-top: 15px;
  }

  > div {
    position: relative;
    border-radius: 5px;
    padding: 6px;
    max-width: 80%;
    min-width: 45px;
    box-shadow: 4px 4px 8px -1px rgba(0, 0, 0, 0.28);
    margin: 4px 5px;
    margin: ${({ isModal }) => (isModal ? "8px 6px" : "4px 5px")};
    flex-direction: column;

    &::after {
      box-sizing: inherit;
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  ${({ isSent, isGerente }) => {
    if (isSent) {
      return isGerente
        ? css`
            justify-content: flex-end;
            display: "flex";
            flex-direction: "column";
            > div {
              background: #d0dcea;

              &::after {
                border-width: 10px 0 0px 10px;
                border-color: transparent transparent transparent #d0dcea;
                bottom: 0;
                right: -6px;
              }
            }
          `
        : css`
            justify-content: flex-end;
            > div {
              background: #dcf8c6;

              &::after {
                border-width: 10px 0 0px 10px;
                border-color: transparent transparent transparent #dcf8c6;
                bottom: 0;
                right: -6px;
              }
            }
          `;
    }

    return css`
      justify-content: flex-start;
      > div {
        background: #fff;

        &::after {
          border-width: 10px 10px 0px 0px;
          border-color: transparent #fff transparent transparent;
          bottom: 0;
          left: -6px;
        }
      }
    `;
  }};
`;

export const Media = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;

  > p {
    font-size: ${({ isModal }) => (isModal ? "14px" : "13px")};
    padding: 20px 4px;
    margin: 0 auto;
  }

  section {
    width: 100%;
    height: 35px;
    padding: 3px 6px;
    margin-top: 3px;
    background: #f0f0f0;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      font-size: ${({ isModal }) => (isModal ? "13px" : "11px")};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  > img {
    max-width: 100%;
    max-height: 100%;
    min-height: 80px;
    object-fit: cover;
  }

  > span {
    font-size: ${({ isModal }) => (isModal ? "14px" : "12px")};
    color: ${({ theme }) => theme.palette.text.darkPrimaryText};
    word-wrap: break-word;
    padding: 9px 7px 0px;
  }
`;

export const DownloadButton = styled.button`
  border-radius: 50%;
  outline: none;
  border: none;
  border: 1px solid ${({ theme }) => theme.palette.icons.darkInactiveIcon};
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 22px;
  cursor: pointer;
  > svg {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.icons.darkInactiveIcon};
  }
`;

export const Spinner = styled(CircularProgress)`
  margin-left: 35px;
  && {
    color: ${({ theme }) => theme.palette.icons.darkInactiveIcon};
  }
`;

export const Info = styled.div`
  position: relative;
  bottom: -4px;
  float: right;
  margin: 2px 3px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isGerente }) =>
    isGerente
      ? css`
          margin-top: 2rem;
        `
      : null};

  > span {
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.darkSecondaryText};
  }
  > div > span {
    position: relative;
    right: 2rem;
    font-style: italic;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.darkSecondaryText};
  }
  > svg {
    margin: 1px 0 0 3px;
    font-size: 16px;
    color: ${({ read }) => (read ? "#57C6F4" : "#A2B691")};
  }
  > div > svg {
    margin-left: 4px;
    font-size: 14px;
    color: #db7c71;
  }
`;

export const ContactLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 6px;
  font-weight: 500;
  font-size: ${({ isModal }) => (isModal ? "14px" : "12px")};
  > span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const PersonIcon = styled(Person)`
  color: #fff;
  background: #aaaaaa;
  border-radius: 50%;
  margin-right: 10px;
  padding: 2px;
`;

export const MessageLabel = styled.li`
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding: 6px 20px;
    background: #d4eaf4;
    border-radius: 4px;
    border: 1px solid #dedede;
    font-weight: 500;
    user-select: none;
    text-align: center;
    color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    ${({ isModal, type }) =>
      isModal
        ? css`
            margin: 5px 35px;
            font-size: 14px;
            min-width: ${type === "DATE" ? "120px" : "350px"};
          `
        : css`
            margin: 5px 20px;
            font-size: 12px;
            min-width: ${type === "DATE" ? "120px" : "180px"};
          `};
  }
`;

export const Text = styled(TextDecoration)`
  font-size: ${({ isModal }) => (isModal ? "14px" : "12px")};
  color: ${({ theme }) => theme.palette.text.darkPrimaryText};
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const EndServiceButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  top: 3px;
  right: -3px;
  svg {
    font-size: 15px;
    color: #7194bb;
  }
`;
