import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 16px 50px;
    margin-bottom: 15px;

    strong {
      font-size: 15px;
    }

    > span {
      margin: 20px 0;
      color: #b1b1b1;
    }
  }
`;
