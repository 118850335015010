import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";

import { Container } from "./styles";

function InfoTransferChat({ handleClick, userName }) {
  return (
    <Container>
      <div>
        <strong>
          <FormattedMessage defaultMessage="Chat transferido" />
        </strong>
        <span>
          <FormattedMessage defaultMessage="Transferido para {usuario}" values={{ usuario: userName }} />{" "}
        </span>

        <Button fullWidth variant="contained" color="primary" onClick={handleClick}>
          <FormattedMessage defaultMessage="Voltar ao início" />
        </Button>
      </div>
    </Container>
  );
}

InfoTransferChat.propTypes = {
  handleClick: PropTypes.func.isRequired,
  userName: PropTypes.string,
};

export default InfoTransferChat;
