import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background: #fff;
  font-size: ${({ isModal }) => (isModal ? "13px" : "11px")};
  font-style: italic;
  color: ${({ theme }) => theme.palette.text.darkSecondaryText};
  > p {
    line-height: 12px;
    margin: 0;
  }
`;

export const LinkButton = styled.span`
  cursor: pointer;
  color: #5082bb;
  line-height: 12px;
  font-size: ${({ isModal }) => (isModal ? "13.5px" : "12px")};

  &:hover {
    color: #0b509e;
  }
`;
