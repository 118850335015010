import styled from "styled-components";

export const Container = styled.div`
  max-height: calc(100% - 38px);
  position: relative;
  height: 100%;
  width: 100%;
  background: #f2f2f2;
`;

export const MessageList = styled.ul`
  height: calc(100% - 41px);
  padding: 15px 6px 12px 6px;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  li:not(.sc-messagebox-separator) + li.sc-messagebox-separator {
    margin-top: 10px;
  }
  li.sc-messagebox-separator + li:not(.sc-messagebox-separator) {
    margin-top: 10px;
  }
`;
