import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 49px;
  left: ${({ offsetLeft }) => offsetLeft - 255}px;
  width: 305px;
  height: 375px;
  background: #f2f2f2;
  z-index: 15;
  box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.3);
`;
