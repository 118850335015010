export default class Call {
  constructor(caller, called, contactName, contactJob, leadId, companyName, callId, talkingDurationSeconds = 0) {
    this.callId = callId;
    this.leadId = leadId;
    this.caller = caller;
    this.called = called;
    this.companyName = companyName;
    this.contactName = contactName;
    this.contactJob = contactJob;
    this.state = null;
    this.talkingDurationSeconds = talkingDurationSeconds;
    this.timer = 0;
  }

  clone() {
    return new Call(
      this.called,
      this.called,
      this.contactName,
      this.contactJob,
      this.callId,
      this.talkingDurationSeconds,
      this.companyName
    );
  }

  changeStateTo(state) {
    this.state = state;
  }
}
