export default class Commands {
  static generateCallData(caller, called, includeCommand = false) {
    const call = {
      caller: `${caller}`.replace(/[^0-9]+/g, ""),
      called: `${called}`.replace(/[^0-9]+/g, ""),
    };

    if (includeCommand) {
      call.command = "CALL";
    }

    return JSON.stringify(call);
  }

  static getCallStatus(callId) {
    return JSON.stringify({
      command: "CALL",
      callId,
    });
  }

  static getCallSumary(callId) {
    return JSON.stringify({
      command: "CALL_SUMARY",
      callId,
    });
  }

  static endCall(callId, includeCommand = false) {
    const endCall = { callId };

    if (includeCommand) {
      endCall.command = "END_CALL";
    }

    return JSON.stringify(endCall);
  }
}
