export const VIEWS = {
  CHAT: "CHAT",
  DISCONNECTING: "DISCONNECTING",
  CONTACTS: "CONTACTS",
  DISCONNECTED: "DISCONNECTED",
  TRANSFER_CHAT: "TRANSFER_CHAT",
  INFO_TRANSFER_CHAT: "INFO_TRANSFER_CHAT",
};

export const MESSAGETYPES = {
  TEXT: 1,
  MEDIA: 2,
  IMAGE: 3,
  AUDIO: 4,
  VIDEO: 5,
  APLICATION: 6,
  CONTACT: 7,
  DOCUMENT: 8,
};

export const STATUS = {
  CONNECTED: 1,
  DISCONNECTED: 0,
};

export const ORIGEM_MSG = {
  SPOTTER: 1,
  LEAD: 2,
};

export const STATUSCHAT = {
  ACTIVE: 1,
  CLOSED: 2,
  WITHOUTRESPONSIBLE: 3,
};

export const STATUSMESSAGE = {
  SENT: 1,
  DELIVERED: 2,
  READ: 3,
  ERROR: 4,
};

export const TIPO_PERFIL = {
  Gerente: 1,
  PreVendedor: 2,
};
