import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 25px;

    strong {
      font-size: 15px;
    }

    > ul {
      padding-left: 20px;
      margin-bottom: 15px;
      li {
        margin: 12px 0;
        color: #b1b1b1;
      }
    }
    > label {
      margin-top: 18px;
      width: fit-content;
      user-select: none;
      margin-right: 0;
      > span:first-child {
        padding: 8px;
      }
      > span + span {
        line-height: 15px;
        letter-spacing: -0.1px;
      }
    }
  }
  button {
    margin-top: 20px;
  }
`;
