import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { Container } from "./styles";

function ChatContainer({ show, containerRef, children }) {
  const [offsetLeft, setOffsetLeft] = useState(window.innerWidth);

  useEffect(
    () => {
      setOffsetLeft(containerRef?.current?.offsetLeft);

      new ResizeObserver(() => {
        setOffsetLeft(containerRef?.current?.offsetLeft);
      }).observe(document.getElementById("navbar"));
    },
    [containerRef]
  );

  return show ? ReactDOM.createPortal(<Container offsetLeft={offsetLeft}>{children}</Container>, document.body) : null;
}

export default ChatContainer;
