import styled from "styled-components";

export const Container = styled.div`
  > section.emoji-mart {
    width: 295px !important;
  }

  .emoji-mart-category-label {
    display: none;
  }

  .emoji-mart-scroll {
    margin-top: 3px;
    height: 140px;
  }

  .emoji-mart-bar {
    display: none;
  }
`;
