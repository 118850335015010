import { eRequestStep, eRequestStatus, eTypesTelephone } from "../../constants";

export default {
  solicitadoExpansao: false,
  etapaSolicitacao: eRequestStep.expansionOrder,
  status: eRequestStatus.awaitingUpdatesFacebook,
  facebookId: "",
  urlArqFacebookId: "",
  nomeArqFacebookId: "",
  nomeFantasia: "",
  cnpj: "",
  urlArqCartaoCnpj: "",
  nomeArqCartaoCnpj: "",
  endereco: "",
  razaoSocial: "",
  atividadePrincipal: "",
  responsavel: "",
  telResponsavel: "",
  emailResponsavel: "",
  site: "",
  urlArqLogoEmpresa: "",
  nomeArqLogoEmpresa: "",
  tipoTelAtivacao: eTypesTelephone.personalFixed,
  ddi: "",
  telefone: "",
  checkConfirmaTermos: false,
};
