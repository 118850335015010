import { defineMessages } from "react-intl";

export const VALUE_NENHUM = 0;
export const MIN_LENGTH_ORDEM = 1;
export const DEFAULT_VALOR_DATA = 30;

export const TIPO_ACAO = {
  ATIVIDADE: 1,
  RECUPERACAO_AUTOMATICA: 2,
  NOTIFICACAO: 3,
  ENVIO_HSM: 4,
  CADENCIA: 5,
  TALLOS: 6,
};

export const EVENTO = {
  CADASTRO_LEAD_API: 1,
  CADASTRO_LEAD_MKT: 2,
  CADASTRO_LEAD_MANUAL: 3,
  RECUPERA_LEAD_MKT: 4,
  RECUPERA_LEAD_API: 5,
  RECUPERA_LEAD_MANUAL: 6,
  QUALIFICACAO: 7,
  DESCARTE: 8,
  FEEDBACK_REUNIAO: 9,
  REUNIAO_AGENDAMENTO: 10,
  REUNIAO_REAGENDAMENTO: 11,
  REUNIAO_CANCELAMENTO: 12,
  EVENTO_FILTRO: 13,
  RESULTADO_LIGACAO: 14,
  DESCARTE_MANUAL: 15,
  CADASTRO_LEAD_IMPORTACAO: 16,
  CADENCIA_DE_EMAILS: 17,
  CANCELAMENTO_REUNIAO_FEEDBACK: 18,
  EVENTO_FILTRO_DESCARTADO: 19,
  LEAD_VENDIDO: 20,
  LEAD_DESCARTADO_NO_FEEDBACK: 21,
  LEAD_QUALIFICADO_NO_FEEDBACK: 22,
  CADASTRO_LEAD_SEARCHING: 23,
  CADASTRO_LEAD_LINKEDIN: 24,
  SEGMENTACAO: 25,
};

export const DATA_REFERENCIA = {
  DATA_AGENDAMENTO: 1,
  DATA_REUNIAO: 2,
};

export const CONDICAO_REFERENCIA = {
  ANTES: 1,
  DEPOIS: 2,
};

export const TIPO_DATA = {
  MINUTOS: 1,
  HORAS: 2,
  DIAS: 3,
  SEMANAS: 4,
  MESES: 5,
};

export const ENTIDADE_CONDICIONAL = {
  ORIGEM: 1,
  SUB_ORIGEM: 2,
  CAMPANHA: 3,
  CARGO: 4,
  MERCADO: 5,
  DORES: 6,
  PRODUTOS: 7,
};

export const AUTOMACAO_EVENTO_PERMISSAO = {
  TODOS: 1,
  GRUPOS: 2,
};

export const TEMPO_NOTIFICACAO = {
  NA_HORA_AGENDADA: 0,
  CINCO: 5,
  QUINZE: 15,
  TRINTA: 30,
  NAO_NOTIFICAR: -1,
};

export const DISPARO_EMAILS_CADENCIA = {
  EMAIL_DISPARADO: 1,
  REPOSTA_RECEBIDA: 2,
  MARCADO_COM_INTERESSE: 3,
  MARCADO_SEM_INTERESSE: 4,
  CLIQUE: 5,
  EMAIL_ABERTO: 6,
};

export const descricaoAcao = defineMessages({
  [TIPO_ACAO.ATIVIDADE]: { defaultMessage: "Atividade" },
  [TIPO_ACAO.RECUPERACAO_AUTOMATICA]: { defaultMessage: "Recuperação Automática" },
  [TIPO_ACAO.NOTIFICACAO]: { defaultMessage: "Notificação" },
  [TIPO_ACAO.ENVIO_HSM]: { defaultMessage: "Envio de HSM (WhatsApp Business)" },
  [TIPO_ACAO.CADENCIA]: { defaultMessage: "Inserir na Cadência de E-mails" },
  [TIPO_ACAO.TALLOS]: { defaultMessage: "Enviar mensagem via RD Station Conversas" },
});

export const descricaoEventos = defineMessages({
  [EVENTO.CADASTRO_LEAD_API]: { defaultMessage: "Cadastro de Lead via API" },
  [EVENTO.CADASTRO_LEAD_MKT]: { defaultMessage: "Cadastro de Lead via Mkt" },
  [EVENTO.CADASTRO_LEAD_MANUAL]: { defaultMessage: "Cadastro de Lead manual" },
  [EVENTO.RECUPERA_LEAD_MKT]: { defaultMessage: "Recuperação de Lead via Mkt" },
  [EVENTO.RECUPERA_LEAD_API]: { defaultMessage: "Recuperação de Lead via API" },
  [EVENTO.RECUPERA_LEAD_MANUAL]: { defaultMessage: "Recuperação de Lead manual" },
  [EVENTO.QUALIFICACAO]: { defaultMessage: "Lead qualificado em qualquer filtro/feedback" },
  [EVENTO.DESCARTE]: { defaultMessage: "Lead descartado em qualquer filtro/feedback" },
  [EVENTO.FEEDBACK_REUNIAO]: { defaultMessage: "Feedback de reunião respondido" },
  [EVENTO.REUNIAO_AGENDAMENTO]: { defaultMessage: "Agendamento" },
  [EVENTO.REUNIAO_REAGENDAMENTO]: { defaultMessage: "Reagendamento" },
  [EVENTO.REUNIAO_CANCELAMENTO]: { defaultMessage: "Cancelamento de Reuniões" },
  [EVENTO.EVENTO_FILTRO]: {
    defaultMessage: "Lead qualificado na conversão de {etapaOrigem} para {etapaDestino}",
  },
  [EVENTO.RESULTADO_LIGACAO]: { defaultMessage: "Resultado de ligação" },
  [EVENTO.DESCARTE_MANUAL]: { defaultMessage: "Descarte manual" },
  [EVENTO.CADASTRO_LEAD_IMPORTACAO]: { defaultMessage: "Cadastro de Lead via Importação" },
  [EVENTO.CADENCIA_DE_EMAILS]: { defaultMessage: "Cadência de e-mails" },
  [EVENTO.CANCELAMENTO_REUNIAO_FEEDBACK]: { defaultMessage: "Cancelamento (Reunião de Feedback)" },
  [EVENTO.EVENTO_FILTRO_DESCARTADO]: {
    defaultMessage: "Lead descartado na conversão de {etapaOrigem} para {etapaDestino}",
  },
  [EVENTO.LEAD_VENDIDO]: { defaultMessage: "Lead Vendido" },
  [EVENTO.LEAD_DESCARTADO_NO_FEEDBACK]: { defaultMessage: "Lead descartado no Feedback de reunião" },
  [EVENTO.LEAD_QUALIFICADO_NO_FEEDBACK]: {
    defaultMessage: "Lead qualificado no Feedback de reunião",
  },
  [EVENTO.CADASTRO_LEAD_SEARCHING]: { defaultMessage: "Cadastro de Lead via Searching" },
  [EVENTO.CADASTRO_LEAD_LINKEDIN]: { defaultMessage: "Cadastro de Lead via Linkedin" },
  [EVENTO.SEGMENTACAO]: { defaultMessage: "Segmentação" },
});

export const descricaoDataReferencia = defineMessages({
  [DATA_REFERENCIA.DATA_AGENDAMENTO]: { defaultMessage: "Data do (re)agendamento" },
  [DATA_REFERENCIA.DATA_REUNIAO]: { defaultMessage: "Data da reunião" },
});

export const descricaoCondicaoReferencia = defineMessages({
  [CONDICAO_REFERENCIA.ANTES]: { defaultMessage: "Antes" },
  [CONDICAO_REFERENCIA.DEPOIS]: { defaultMessage: "Depois" },
});

export const descricaoTipoData = defineMessages({
  [TIPO_DATA.MINUTOS]: { defaultMessage: "Minuto(s)" },
  [TIPO_DATA.HORAS]: { defaultMessage: "Hora(s)" },
  [TIPO_DATA.DIAS]: { defaultMessage: "Dia(s)" },
  [TIPO_DATA.SEMANAS]: { defaultMessage: "Semana(s)" },
  [TIPO_DATA.MESES]: { defaultMessage: "Mês(es)" },
});

export const descricaoEntidadeCondicional = defineMessages({
  [ENTIDADE_CONDICIONAL.ORIGEM]: { defaultMessage: "Origem" },
  [ENTIDADE_CONDICIONAL.SUB_ORIGEM]: { defaultMessage: "Sub-Origem" },
  [ENTIDADE_CONDICIONAL.CAMPANHA]: { defaultMessage: "Campanha" },
  [ENTIDADE_CONDICIONAL.CARGO]: { defaultMessage: "Cargo" },
  [ENTIDADE_CONDICIONAL.MERCADO]: { defaultMessage: "Mercado" },
  [ENTIDADE_CONDICIONAL.DORES]: { defaultMessage: "Dores" },
  [ENTIDADE_CONDICIONAL.PRODUTOS]: { defaultMessage: "Produtos" },
});

export const descricaoAutomacaoEventoPermissao = defineMessages({
  [AUTOMACAO_EVENTO_PERMISSAO.TODOS]: { defaultMessage: "Todos" },
  [AUTOMACAO_EVENTO_PERMISSAO.GRUPOS]: { defaultMessage: "Grupos" },
});

export const descricaoTempoNotificacao = defineMessages({
  [TEMPO_NOTIFICACAO.NA_HORA_AGENDADA]: { defaultMessage: "Na hora agendada" },
  [TEMPO_NOTIFICACAO.CINCO]: { defaultMessage: "5 minutos antes" },
  [TEMPO_NOTIFICACAO.QUINZE]: { defaultMessage: "15 minutos antes" },
  [TEMPO_NOTIFICACAO.TRINTA]: { defaultMessage: "30 minutos antes" },
  [TEMPO_NOTIFICACAO.NAO_NOTIFICAR]: { defaultMessage: "Não notificar" },
});

export const descricaoDisparoEmailsCadencia = defineMessages({
  [DISPARO_EMAILS_CADENCIA.EMAIL_DISPARADO]: { defaultMessage: "E-mail Entregue" },
  [DISPARO_EMAILS_CADENCIA.REPOSTA_RECEBIDA]: { defaultMessage: "Resposta Recebida" },
  [DISPARO_EMAILS_CADENCIA.MARCADO_COM_INTERESSE]: { defaultMessage: "Marcado Com Interesse" },
  [DISPARO_EMAILS_CADENCIA.MARCADO_SEM_INTERESSE]: { defaultMessage: "Marcado Sem Interesse" },
  [DISPARO_EMAILS_CADENCIA.CLIQUE]: { defaultMessage: "Link clicado" },
  [DISPARO_EMAILS_CADENCIA.EMAIL_ABERTO]: { defaultMessage: "E-mail Aberto" },
});

export const textoAcaoMessages = defineMessages({
  CriarAtividade: {
    defaultMessage: "Criar atividade <b> {tituloAcao} </b> configurada para {textoCondicional}",
  },
  RecuperacaoAutomatica: {
    defaultMessage: "<b>Recuperação automática</b> configurada para {textoCondicional}",
  },
  EnvioHSM: {
    defaultMessage: "Envio de HSM (WhatsApp Business) <b> {templateHsm} </b> configurado para {textoCondicional}",
  },
  Notificacao: {
    defaultMessage: "<b>Notificação</b> configurado para {textoCondicional}",
  },
  Cadencia: {
    defaultMessage: "Inserir na Cadência de e-mails <b> {tituloAcao} </b> configurado para {textoCondicional}",
  },
  Tallos: {
    defaultMessage:
      "Enviar mensagem via RD Station Conversas com template <b> {tituloAcao} </b> configurado para {textoCondicional}",
  },
});

export const textoAcaoCondicionalMessages = defineMessages({
  Depois: { defaultMessage: "{tempoExecucaoWorkflow} depois {proximaCondicional}" },
  Da: { defaultMessage: "da <b>{tituloEvento}</b>" },
  Do: { defaultMessage: "do <b>{tituloEvento}</b>" },
});

export const textoCondicaoReferencia = defineMessages({
  [CONDICAO_REFERENCIA.ANTES]: { defaultMessage: "{tempoExecucaoWorkflow} antes {proximaCondicional}" },
  [CONDICAO_REFERENCIA.DEPOIS]: { defaultMessage: "{tempoExecucaoWorkflow} depois {proximaCondicional}" },
});

export const EVENTOS_COM_SUBTIPOS = [
  EVENTO.CADENCIA_DE_EMAILS,
  EVENTO.DESCARTE_MANUAL,
  EVENTO.RESULTADO_LIGACAO,
  EVENTO.SEGMENTACAO,
];

export const EVENTOS_COM_ACAO_NOTIFICACAO = [
  EVENTO.CADASTRO_LEAD_SEARCHING,
  EVENTO.CADASTRO_LEAD_LINKEDIN,
  EVENTO.CADASTRO_LEAD_API,
  EVENTO.CADASTRO_LEAD_MKT,
  EVENTO.CADENCIA_DE_EMAILS,
  EVENTO.CANCELAMENTO_REUNIAO_FEEDBACK,
  EVENTO.FEEDBACK_REUNIAO,
  EVENTO.RECUPERA_LEAD_API,
  EVENTO.RECUPERA_LEAD_MKT,
];

export const EVENTOS_COM_ACAO_RECUPERACAO_AUTOMATICA = [
  EVENTO.DESCARTE,
  EVENTO.DESCARTE_MANUAL,
  EVENTO.LEAD_DESCARTADO_NO_FEEDBACK,
  EVENTO.EVENTO_FILTRO_DESCARTADO,
];

export const EVENTOS_BASIC_REMOVER = [
  EVENTO.CADASTRO_LEAD_IMPORTACAO,
  EVENTO.CADENCIA_DE_EMAILS,
  EVENTO.CANCELAMENTO_REUNIAO_FEEDBACK,
  EVENTO.LEAD_DESCARTADO_NO_FEEDBACK,
  EVENTO.CADASTRO_LEAD_SEARCHING,
  EVENTO.CADASTRO_LEAD_LINKEDIN,
];
