import styled, { css } from "styled-components";
import { List, ListItem } from "@material-ui/core";

export const Container = styled.span`
  background: #eee;
  color: #444;
  padding: 0px 6px;
  border-radius: 7px;
  margin: 1px;
  display: inline-block;
  height: 26px;
  > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export const FreeText = styled.div`
  min-width: 25px;
  position: relative;
  line-height: 16px;
  > input {
    border: none;
    outline: none;
    background: transparent;
    color: transparent;
    position: absolute;
    caret-color: #000;
    padding: 0;
    width: 100%;
    min-width: 25px;
    font-size: 14px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.13132px;
  }

  color: ${({ hasValue, theme }) =>
    hasValue ? theme.palette.text.darkPrimaryText : theme.palette.text.darkSecondaryText};
`;

export const Suggested = styled.div`
  min-width: 25px;
  position: relative;
  line-height: 16px;
  transition: all 0.2s ease-in-out;
  padding-right: 15px;
  svg {
    display: inline-block;
    color: inherit;
    font-size: 18px;
    position: absolute;
    right: -5px;
    top: -1px;
    transform: rotate(90deg);
    cursor: pointer;
  }

  color: ${({ hasValue, theme }) =>
    hasValue ? theme.palette.text.darkPrimaryText : theme.palette.text.darkSecondaryText};

  ${({ hasOpenned }) =>
    hasOpenned &&
    css`
      padding-right: 12px;
      svg {
        display: inline-block;
      }
    `};
`;

export const ScList = styled(List)`
  && {
    max-height: 220px;
    overflow-y: auto;

    > b {
      font-size: 13px;
      padding: 0 0 3px 13px;
      display: block;
      color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    }
  }
`;

export const SCListItem = styled(ListItem)`
  && {
    cursor: pointer;
    padding: 10px 12px;
    margin: 1px 0;
    display: flex;
    align-items: center;

    > p {
      margin: 0 0 0 5px;
      color: inherit;
      font-size: 12px;
      color: ${({ theme }) => theme.palette.text.darkSecondaryText};
    }

    &:hover {
      background: #f2f2f2;
    }
  }
`;
