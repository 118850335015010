import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { IconButton } from "@material-ui/core";
import { Tooltip } from "react-tippy";
import WhatsAppIcon from "./WhatsAppIcon";
import Badge from "./Badge";
import Header from "./Header";
import ChatContainer from "./ChatContainer";
import Chat from "./Chat";
import ContatoItem from "./ContatoItem";
import Disconnecting from "./Disconnecting";
import Disconnected from "./Disconnected";
import TransferChat from "./TransferChat";
import InfoTransferChat from "./InfoTransferChat";
import EndChat from "./EndChat";
import ModalHSMSend from "./ModalHSMSend";

import { Container, CloseIcon, ContentCenter, ListContacts } from "./styles";
import { VIEWS, STATUS } from "./constants";
import { eRequestStatus } from "./RegistrationRequest/constants";

import socket, { eActions } from "../../websocket/socket";
import { setIdActiveChat, forceUpdateState } from "../../store/reducers/whatsapp/actions";
import {
  getUnreadChatMessages,
  getActiveContactChat,
  getContactsList,
  getMessages,
} from "../../store/reducers/whatsapp/selectors";
import {
  changeStatus,
  loadMessages,
  sendMessage,
  loadContacts,
  receiveMessageWithSocket,
  updateMessageWithSocket,
  updateMessageReadWithSocket,
  updateClientStatusSocket,
  endChat,
  transferChat,
  showLeadDetails,
  sendFile,
} from "./services";
import { newHsmMessage } from "./RegistrationRequest/services";

import { usePermission, useTipoPlanoSpotter } from "../../hooks";
import permissions from "../../_common/permissions";
import { extractDDIPhone } from "../../_common/utils/phone";
import ModalVincularContato from "../whatsapp/ModalVincularContato";

function IntegracaoWattsApp({
  loading,
  status,
  ddi,
  telefone,
  contactsList,
  unreadMessages,
  setActiveChat,
  activeChat,
  messages,
  sharedParams,
  forceUpdateListContacts,
  isGerente,
  isVendedor,
  habilitado,
  statusSolicitacao,
}) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(VIEWS.DISCONNECTED);
  const canTransferChat = usePermission(permissions.TRANSFERIRCHATS);
  const [modalVincularContatoOpen, setModalVincularContatoOpen] = useState(false);
  const handleVincularContato = () => {
    setModalVincularContatoOpen(!modalVincularContatoOpen);
  };

  if (!habilitado || statusSolicitacao === eRequestStatus.cancelled) {
    if (!isGerente) return null;
    return (
      <Container>
        {isSpotterFull && (
          <Tooltip title={intl.formatMessage({ defaultMessage: "WhatsApp Business" })}>
            <Link to={"/spotter/whatsapp"} target="_blank">
              <IconButton>
                <WhatsAppIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </Container>
    );
  }

  if (isVendedor) return null;

  useEffect(() => {
    socket.on(eActions.WHATSAPP_RECEIVE_MESSAGE, receiveMessageWithSocket);
    socket.on(eActions.WHATSAPP_UPDATE_MESSAGE, updateMessageWithSocket);
    socket.on(eActions.WHATSAPP_UPDATE_MESSAGE_READ, updateMessageReadWithSocket);
    socket.on(eActions.WHATSAPP_ALTERAR_STATUS_CHAT, updateClientStatusSocket);
    return () => {
      socket.off(eActions.WHATSAPP_RECEIVE_MESSAGE, receiveMessageWithSocket);
      socket.off(eActions.WHATSAPP_UPDATE_MESSAGE, updateMessageWithSocket);
      socket.off(eActions.WHATSAPP_UPDATE_MESSAGE_READ, updateMessageReadWithSocket);
      socket.off(eActions.WHATSAPP_ALTERAR_STATUS_CHAT, updateClientStatusSocket);
    };
  }, []);

  useEffect(
    () => {
      switch (status) {
        case STATUS.CONNECTED: {
          socket.on(eActions.WHATSAPP_RELOAD_CONTACTS, loadContacts);
          loadContacts().then(() => setView(VIEWS.CONTACTS));
          break;
        }
        case STATUS.DISCONNECTED: {
          socket.off(eActions.WHATSAPP_RELOAD_CONTACTS, loadContacts);
          setView(VIEWS.DISCONNECTED);
          break;
        }
        default:
          break;
      }
      return () => {
        socket.off(eActions.WHATSAPP_RELOAD_CONTACTS, loadContacts);
      };
    },
    [status]
  );

  useEffect(() => {
    const timer = setInterval(forceUpdateListContacts, 30 * 1000);
    return () => clearInterval(timer);
  }, []);

  function handleOpenWhatsApp() {
    setOpen(isOpen => {
      if (isOpen && status === STATUS.CONNECTED) {
        setActiveChat(null);
        setView(VIEWS.CONTACTS);
      }
      return !isOpen;
    });
  }

  async function openChat(contact) {
    await loadMessages(contact);
    await setActiveChat(contact.telefoneLead);
    setView(VIEWS.CHAT);
  }

  const renderBody = () => {
    switch (view) {
      case VIEWS.CONTACTS: {
        if (contactsList.length > 0)
          return (
            <ListContacts>
              {contactsList.map(contact => (
                <ContatoItem key={contact.telefoneLead} contact={contact} onClick={() => openChat(contact)} />
              ))}
            </ListContacts>
          );
        return (
          <ContentCenter>
            <ErrorOutlineIcon />
            <p>
              <FormattedMessage defaultMessage="Nenhum contato disponível..." />
            </p>
          </ContentCenter>
        );
      }
      case VIEWS.CHAT:
        return (
          <Chat
            chat={activeChat}
            messages={messages}
            onSendMessage={message => sendMessage(message, activeChat)}
            onSendNewFile={(file, updateProgress) => sendFile(file, activeChat, updateProgress)}
            handleNewHsmMessage={() => {
              const telefoneDDI = extractDDIPhone(activeChat.telefoneLead);

              newHsmMessage({
                destinatario: {
                  id: activeChat.leadId,
                  leadId: activeChat.leadId,
                  nome: activeChat.nomeLead,
                  ddi: telefoneDDI.ddi,
                  telefone: telefoneDDI.phone,
                },
              });
            }}
          />
        );

      case VIEWS.DISCONNECTING:
        return <Disconnecting handleDisconnect={() => changeStatus(STATUS.DISCONNECTED)} />;

      case VIEWS.DISCONNECTED:
        return <Disconnected phone={telefone} ddi={ddi} handleConnect={() => changeStatus(STATUS.CONNECTED)} />;

      case VIEWS.TRANSFER_CHAT:
        return (
          <TransferChat
            handleTransfer={(user, transferLead) =>
              transferChat(activeChat, user, transferLead).then(() => setView(VIEWS.INFO_TRANSFER_CHAT))
            }
          />
        );

      case VIEWS.INFO_TRANSFER_CHAT:
        return <InfoTransferChat userName={sharedParams.user.nome} handleClick={() => setView(VIEWS.CONTACTS)} />;

      case VIEWS.END_CHAT:
        return <EndChat handleEnd={comments => endChat(activeChat, comments).then(() => setView(VIEWS.CONTACTS))} />;

      default:
        return null;
    }
  };

  return (
    <Container ref={containerRef}>
      <ModalHSMSend />

      <Tooltip
        title={`${
          open ? intl.formatMessage({ defaultMessage: "Fechar" }) : intl.formatMessage({ defaultMessage: "Abrir" })
        } WhatsApp`}
      >
        <IconButton onClick={handleOpenWhatsApp}>
          {open ? (
            <CloseIcon />
          ) : (
            <Badge counter={unreadMessages}>
              <WhatsAppIcon />
            </Badge>
          )}
        </IconButton>
      </Tooltip>
      <ModalVincularContato
        modalVincularContatoOpen={modalVincularContatoOpen}
        handleVincularContato={handleVincularContato}
        infosChat={activeChat}
      />

      <ChatContainer containerRef={containerRef} show={open}>
        <Header
          loading={loading}
          canTransferChat={canTransferChat}
          contact={activeChat}
          activeView={view}
          handleClickBack={() => {
            setActiveChat(null);
            setView(VIEWS.CONTACTS);
          }}
          handleChangeView={setView}
          handleOpenLeadDetails={showLeadDetails}
          handleNewHsmMessage={newHsmMessage}
          handleVincularContato={handleVincularContato}
        />
        {renderBody()}
      </ChatContainer>
    </Container>
  );
}

const mapStateToProps = state => ({
  habilitado: state.whatsApp.habilitado,
  isGerente: state.usuario.isGerente,
  isVendedor: state.usuario.isVendedor,
  loading: state.whatsApp.loading,
  status: state.whatsApp.status,
  telefone: state.whatsApp.telefone,
  ddi: state.whatsApp.ddi,
  sharedParams: state.whatsApp.sharedParams,
  statusSolicitacao: state.whatsApp.statusSolicitacao,
  messages: getMessages(state),
  contactsList: getContactsList(state),
  unreadMessages: getUnreadChatMessages(state),
  activeChat: getActiveContactChat(state),
});

const mapDispatchToProps = dispatch => ({
  setActiveChat: id => dispatch(setIdActiveChat(id)),
  forceUpdateListContacts: () => dispatch(forceUpdateState()),
});

IntegracaoWattsApp.propTypes = {
  loading: PropTypes.bool,
  isGerente: PropTypes.bool,
  isVendedor: PropTypes.bool,
  habilitado: PropTypes.bool,
  status: PropTypes.number,
  ddi: PropTypes.string,
  telefone: PropTypes.string,
  unreadMessages: PropTypes.number.isRequired,
  contactsList: PropTypes.array.isRequired,
  activeChat: PropTypes.object,
  messages: PropTypes.array,
  sharedParams: PropTypes.object,
  setActiveChat: PropTypes.func,
  forceUpdateListContacts: PropTypes.func,
  statusSolicitacao: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegracaoWattsApp);
