import React, { memo, useMemo } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import VideoIcon from "@material-ui/icons/Videocam";
import PersonIcon from "@material-ui/icons/Person";
import { Tooltip } from "@material-ui/core";

import TextDecoration from "../Shared/TextDecoration";
import { truncateString, getIniciaisDeUmNome } from "../../../_common/utils/string";
import { extractDDIPhone } from "../../../_common/utils/phone";
import { MESSAGETYPES, ORIGEM_MSG } from "../constants";
import RegisterLead from "../../lead/components/cadastrar";

import {
  Container,
  AvatarContainer,
  Content,
  StyledAvatar,
  StyledAvatarButton,
  Name,
  Message,
  Time,
  Badge,
} from "./styles";
import { getChatIdentifier, loadContacts } from "../services";

function ContactItem({ contact, onClick }) {
  const intl = useIntl();

  const initialsNameLead = useMemo(() => (contact.nomeLead?.length ? getIniciaisDeUmNome(contact.nomeLead) : null), [
    contact.nomeLead,
  ]);

  const formattedDate = useMemo(
    () => {
      const now = new Date();
      const pastDays = moment(now)
        .clone()
        .startOf("day")
        .diff(
          moment(contact.dateTimezone, "DD/MM/YYYY HH:mm:ss")
            .clone()
            .startOf("day"),
          "days"
        );

      if (pastDays === 0) {
        return moment(contact.dateTimezone, "DD/MM/YYYY HH:mm:ss").format("HH:mm");
      } else if (pastDays === 1) {
        return intl.formatMessage({ defaultMessage: "Ontem" }).toUpperCase();
      }
      return moment(contact.dateTimezone, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
    },
    [contact.dateTimezone]
  );

  const leadName = useMemo(
    () => {
      const truncateIn = contact.minutosRestanteChatHabilitado ? 15 : 22;
      const name = getChatIdentifier(contact);

      return {
        name,
        truncateName: truncateString(name, truncateIn),
        showTooltip: name.length > truncateIn,
      };
    },
    [contact]
  );

  const Snippet = useMemo(
    () => {
      switch (contact.tipo) {
        case MESSAGETYPES.TEXT:
          return (
            <Message>
              <TextDecoration
                message={truncateString(
                  `${contact.origem === ORIGEM_MSG.SPOTTER ? intl.formatMessage({ defaultMessage: "Você:" }) : ""}${
                    contact.snippet
                  }`,
                  30
                )}
              />
            </Message>
          );
        case MESSAGETYPES.IMAGE:
          return (
            <Message>
              <CameraAltIcon />
              <FormattedMessage defaultMessage="Imagem" />
            </Message>
          );
        case MESSAGETYPES.AUDIO:
          return (
            <Message>
              <AudiotrackIcon />
              Áudio
            </Message>
          );
        case MESSAGETYPES.VIDEO:
          return (
            <Message>
              <VideoIcon />
              Video
            </Message>
          );
        case MESSAGETYPES.CONTACT:
          return (
            <Message>
              <PersonIcon />
              <FormattedMessage defaultMessage="Contato" />
            </Message>
          );
        default:
          return (
            <Message>
              <AttachFileIcon />
              <FormattedMessage defaultMessage="Anexo" />
            </Message>
          );
      }
    },
    [contact.tipo, contact.origem, contact.snippet]
  );

  return (
    <Container readOnly={contact.envioMsgBloqueado}>
      <AvatarContainer>
        {initialsNameLead ? (
          <StyledAvatar>{initialsNameLead}</StyledAvatar>
        ) : (
          <Tooltip
            enterDelay={200}
            title={intl.formatMessage({ defaultMessage: "Cadastrar Lead" })}
            placement="bottom-start"
          >
            <StyledAvatarButton>
              <RegisterLead
                type="icon"
                icon={<PersonAddIcon />}
                callback={leadId => {
                  if (leadId) loadContacts();
                }}
                initialFormValues={{
                  telefones: [
                    {
                      tipo: { id: 1 },
                      principal: true,
                      ...(() => {
                        const { ddi, phone: numero } = extractDDIPhone(contact.telefoneLead);
                        return { ddi, numero };
                      })(),
                    },
                  ],
                }}
              />
            </StyledAvatarButton>
          </Tooltip>
        )}
      </AvatarContainer>

      <Content onClick={onClick}>
        <div>
          <Tooltip enterDelay={200} title={leadName.showTooltip ? leadName.name : ""} placement="top-start">
            <Name>{leadName.truncateName}</Name>
          </Tooltip>

          {contact.minutosRestanteChatHabilitado ? (
            <Time danger>
              <FormattedMessage
                defaultMessage="Encerra em {tempo} minutos"
                values={{ tempo: contact.minutosRestanteChatHabilitado }}
              />
            </Time>
          ) : (
            <Time>{formattedDate}</Time>
          )}
        </div>
        <div>
          {Snippet}
          {contact.qtdNaoLidos > 0 && <Badge>{contact.qtdNaoLidos}</Badge>}
        </div>
      </Content>
    </Container>
  );
}

ContactItem.propTypes = {
  contact: PropTypes.object,
  onClick: PropTypes.func,
};

export default memo(ContactItem);
