import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { withStyles, RadioGroup, Radio, FormControlLabel, Checkbox } from "@material-ui/core";
import SearchSelectDebounce from "../../../../_common/components/SearchSelectDebounce";

const optionsPesquisa = intl => [
  {
    id: "pesquisaPorLead",
    value: true,
    label: intl.formatMessage({ defaultMessage: "Pesquisar por Lead" }),
  },
  {
    id: "pesquisaPorContato",
    value: false,
    label: intl.formatMessage({ defaultMessage: "Pesquisar por Contato" }),
  },
];

const styles = {
  radio: {
    flexDirection: "row",
    marginLeft: 0,
    marginRight: 0,
    width: "50%",
  },
};

const initialValues = {
  pesquisaPorLead: true,
  pesquisaPorContato: false,
  transferirChat: true,
};

const messages = defineMessages({
  escolherLead: {
    defaultMessage: "Escolher Lead",
  },
  escolherContato: {
    defaultMessage: "Escolher Contato",
  },
  pesquisarLead: {
    defaultMessage: "Pesquisar Lead...",
  },
  pesquisarContato: {
    defaultMessage: "Pesquisar Contato...",
  },
});

function FormVincularContato({ classes, handleUser, handleTipoDeVinculacao, handleTransferirChat }) {
  const intl = useIntl();
  const [user, setUser] = useState(null);

  return (
    <Formik initialValues={initialValues}>
      {({ values, setValues }) => (
        <Form id="form-vincular-contato">
          <Field
            row
            name="tipoPesquisa"
            component={RadioGroup}
            onChange={(e, value) => {
              setValues({
                ...values,
                pesquisaPorLead: value === "true",
                pesquisaPorContato: value === "true",
              });
              handleTipoDeVinculacao(!values.pesquisaPorLead);
            }}
          >
            {optionsPesquisa(intl).map(option => (
              <FormControlLabel
                classes={{
                  root: classes.radio,
                }}
                key={option.id}
                control={
                  <Radio
                    checked={values.pesquisaPorLead === option.value}
                    color="primary"
                    id={option.id}
                    value={option.value}
                  />
                }
                label={option.label}
                labelPlacement="end"
              />
            ))}
          </Field>

          <SearchSelectDebounce
            value={user}
            url={term =>
              values.pesquisaPorLead
                ? `/api/pipeline/lead/FiltrarPorNome?nome=${term}&quantidadePorPagina=20`
                : `/api/pipeline/contatos/FiltrarPorNome?nome=${term}&quantidadePorPagina=20`
            }
            width={550}
            label={intl.formatMessage(values.pesquisaPorLead ? messages.escolherLead : messages.escolherContato)}
            labelSearch={intl.formatMessage(
              values.pesquisaPorLead ? messages.pesquisarLead : messages.pesquisarContato
            )}
            attribute={values.pesquisaPorLead ? "descricao" : "nomesLeadContato"}
            onChange={item => {
              setUser(item);
              handleUser(item);
            }}
          />
          <Field
            as={FormControlLabel}
            checked={values.transferirChat}
            control={
              <Checkbox
                name="transferirChat"
                value={values.transferirChat}
                onChange={() => {
                  setValues({
                    ...values,
                    transferirChat: !values.transferirChat,
                  });
                  handleTransferirChat();
                }}
              />
            }
            label={"Transferir chat ao pré-vendedor responsável pelo Lead"}
            name="transferirChat"
          />
        </Form>
      )}
    </Formik>
  );
}

FormVincularContato.propTypes = {
  handleUser: PropTypes.func,
  handleTipoDeVinculacao: PropTypes.func,
  handleTransferirChat: PropTypes.func,
};

export default withStyles(styles)(FormVincularContato);
